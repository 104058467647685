// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import * as React from "react";

const numberOfLogos = 21;

const logoPaths = Array.from({ length: numberOfLogos }, (_, index) => ({
  src: `/laureats/Diapositive${index + 1}.PNG`,
  alt: `Logo ${index + 1}`,
}));


function Sector() {

  return (
<Container style={{ paddingTop: '16px', paddingBottom: '100px' }}>
  <Grid container spacing={3}>
    {logoPaths.map((logoPath, index) => (
      <Grid key={index} item xs={12} sm={4} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {/* Use logoPath.src as the source URL */}
        <a href={logoPath.src} target="_blank" rel="noopener noreferrer">
          <img
            src={logoPath.src}
            alt={`Logo ${index + 1}`}
            style={{ width: '100%', height: 'auto', maxWidth: 'none' }}
          />
        </a>
      </Grid>
    ))}
  </Grid>
</Container>


  );
}

export default Sector;
