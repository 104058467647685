/*
=========================================================
* Otis Kit PRO - v2.0.0
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKTypography from "components/MKTypography";

// Otis Kit PRO examples
import Footer from "examples/Footers/CenteredFooter";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Routes
import footerRoutes from "footer.routes";
import routes from "routes";

// Image
import bgImage from "assets/images/annie-spratt.jpg";
import React, { useState } from "react";
import Popup from "pages/Support/ContactUs/Popup";

function ContactUs() {
  const [toSend, setToSend] = useState({
    name: "",
    email: "",
    message: "",
    phone: "",
    company: "",
    function: "",
    sector: "",
  });

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  const handleChange2 = (value) => {
    setToSend({ ...toSend, function: value });
  };

  const handleChange3 = (value) => {
    setToSend({ ...toSend, sector: value });
  };

  return (
    <>
      <MKBox variant="gradient" bgColor="dark" shadow="sm">
        <DefaultNavbar routes={routes} transparent relative light center />
      </MKBox>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} lg={6}>
          <MKBox
            display={{ xs: "none", lg: "flex" }}
            width="calc(100% - 2rem)"
            height="calc(100vh - 2rem)"
            borderRadius="lg"
            ml={2}
            mt={2}
            sx={{ backgroundImage: `url(${bgImage})` }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={7}
          lg={6}
          xl={4}
          ml={{ xs: "auto", lg: 6 }}
          mr={{ xs: "auto", lg: 6 }}
        >
          <MKBox
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 20, sm: 18, md: 20 }}
            mb={{ xs: 20, sm: 18, md: 20 }}
            mx={3}
          >
            <MKBox
              variant="gradient"
              bgColor="info"
              coloredShadow="info"
              borderRadius="lg"
              p={2}
              mx={2}
              mt={-3}
            >
              <MKTypography variant="h3" color="white">
                Contactez-nous !
              </MKTypography>
            </MKBox>
            <MKBox p={3}>
              <MKTypography variant="body2" color="text" mb={3}>
                Nous vous répondrons dans le plus bref délai.
              </MKTypography>
              <MKBox
                width="100%"
                component="form"
                method="post"
                autocomplete="off"
              >
                <FormControl>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        variant="standard"
                        label="Nom - Prénom"
                        fullWidth
                        name="name"
                        value={toSend.name}
                        onChange={handleChange}
                        required="true"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        type="email"
                        variant="standard"
                        label="E-mail"
                        fullWidth
                        name="email"
                        value={toSend.email}
                        onChange={handleChange}
                        required="true"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <MKInput
                        type="tel"
                        variant="standard"
                        label="Téléphone"
                        fullWidth
                        name="phone"
                        value={toSend.phone}
                        onChange={handleChange}
                        required="true"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <MKInput
                        variant="standard"
                        label="Raison sociale"
                        fullWidth
                        name="company"
                        value={toSend.company}
                        onChange={handleChange}
                        required="true"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        onChange={(event, value) => handleChange2(value)}
                        options={[
                          "Direction du réseau",
                          "Direction commerciale",
                          "Direction marketing",
                          "Direction générale",
                          "Autre",
                        ]}
                        renderInput={(params) => (
                          <MKInput
                            {...params}
                            label="Votre fonction"
                            variant="standard"
                            required="true"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        onChange={(event, value) => handleChange3(value)}
                        options={[
                          "Agences immobilières",
                          "Entretien automobile",
                          "Banque",
                          "Assurance",
                          "Parfumerie / Cosmetiques & Esthétique",
                          "GMS",
                          "Optioque & Audition",
                          "Bricolage & Jardinage",
                          "Entretien automobile & Constrôle technique",
                          "Pare-brise",
                          "Cusine & Salle de bain",
                          "Veranda pergola",
                          "Salles de sport / Fitness",
                          "Magasins de sport",
                          "Autre",
                        ]}
                        renderInput={(params) => (
                          <MKInput
                            {...params}
                            label="Votre secteur d'activité"
                            variant="standard"
                            required="true"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MKInput
                        variant="standard"
                        label="Votre demande"
                        placeholder="Décrivez votre demande..."
                        multiline
                        fullWidth
                        rows={6}
                        name="message"
                        value={toSend.message}
                        onChange={handleChange}
                        required="true"
                      />
                    </Grid>
                  </Grid>
                </FormControl>
                <Grid
                  container
                  item
                  justifyContent="center"
                  xs={12}
                  mt={5}
                  mb={2}
                >
                  <Popup data={toSend} />
                </Grid>
              </MKBox>
            </MKBox>
          </MKBox>
        </Grid>
      </Grid>
      <MKBox>
        <Footer content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default ContactUs;
