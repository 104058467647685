// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Otis Kit PRO examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
import bg from "assets/images/offre.png";

function Prestation() {
  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid container spacing={3}>
          <Grid
            xs={12}
            item
            flexDirection="column"
            alignItems="center"
            mx="auto"
            textAlign="center"
          >
            <MKBox mb={5}>
              <MKTypography variant="h2" mb={1}>
                Comment s&apos;organise notre prestation ?
              </MKTypography>
              <MKTypography variant="body1" color="text">
                Vous définissez le périmètre à surveiller :
              </MKTypography>
            </MKBox>
          </Grid>
          <Grid item xs={12} md={12} lg={4}>
            <Grid item>
              <MKBox mb={5}>
                <DefaultInfoCard
                  icon="apartment"
                  title="Les établissements de votre réseau"
                  color="info"
                  description="Vous nous fournissez la liste des sites à intégrer dans l'étude."
                />
              </MKBox>
            </Grid>
            <Grid item>
              <MKBox mb={5}>
                <DefaultInfoCard
                  icon="store"
                  title="Les réseaux concurrents"
                  color="info"
                  description="Vous nous indiquez les enseignes concurrentes à suivre."
                />
              </MKBox>
            </Grid>
            <Grid item>
              <MKBox mb={5}>
                <DefaultInfoCard
                  icon="update"
                  title="La fréquence de mise à jour des rapports"
                  description="Vous déterminez la fréquence d'update des données."
                  color="info"
                />
              </MKBox>
            </Grid>
            <Grid item textAlign="center">
              <MKButton
                variant="gradient"
                color="info"
                href="support/contact-us"
              >
                Contactez-nous !
              </MKButton>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={8} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <MKBox
              width="100%"
              height="100%"
              borderRadius="lg"
              sx={{
                backgroundImage: `url(${bg})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Prestation;
