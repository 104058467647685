/*
=========================================================
* Otis Kit PRO - v2.0.0
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Otis Kit PRO examples
import Footer from "examples/Footers/CenteredFooter";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Coworking page sections
import Prestation from "pages/Offer/sections/Prestation";

// Routes
import footerRoutes from "footer.routes";
import routes from "routes";

// Images
import bgImage from "assets/images/bg-offre-header.png";

function Coworking() {
  return (
    <>
      <MKBox variant="gradient" bgColor="dark" shadow="sm">
        <DefaultNavbar routes={routes} transparent relative light center />
      </MKBox>
      <MKBox
        minHeight="85vh"
        width="100%"
        sx={{
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { gradients },
          }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.5),
              rgba(gradients.dark.state, 0.5)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
          minHeight: 550,
        }}
      >
        <Container>
          <Grid
            container
            item
            justifyContent={{ xs: "center", md: "start" }}
            sx={{ textAlign: { xs: "center", md: "left" } }}
            spacing={5}
          >
            <Grid item xs={12} sm={12} md={7}>
              <MKTypography
                variant="h1"
                color="white"
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("lg")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                Retrouvez en quelques clics <br /> les KPI qui vont booster
                votre stratégie web-to-store !
              </MKTypography>
              <MKTypography
                variant="body1"
                color="white"
                opacity={0.8}
                fontWeight="bold"
                py={2}
              >
                Analysez les évolutions de votre marché :
                <MKBox component="ul" my={1} px={4}>
                  <MKBox component="li" color="white">
                    Magasin par magasin
                  </MKBox>
                  <MKBox component="li" color="white">
                    Sur une zone géographique déterminée
                  </MKBox>
                  <MKBox component="li" color="white">
                    Par niveau de notation
                  </MKBox>
                  <MKBox component="li" color="white">
                    En comparaison avec la concurrence…
                  </MKBox>
                </MKBox>
                <br />
                <MKButton
                  variant="gradient"
                  color="info"
                  href="https://calendly.com/m-berger-1"
                  size="large"
                >
                  je veux une demo !
                </MKButton>
              </MKTypography>
            </Grid>
            <Grid item sm={12} md={5}>
              <iframe
                src="https://player.vimeo.com/video/746574818?h=00b994dbd6"
                width="100%"
                height="100%"
                frameBorder="0"
                scrolling="no"
                allow="autoplay; fullscreen"
                allowFullScreen
                title="Vasano3D"
              />
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -4,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
            rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Prestation />
      </Card>
      <MKBox>
        <Footer content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Coworking;
