// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// HelpCenter page components
import ListItem from "examples/ListItem";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  paper: {
    height: "100%",
  },
});

function Presentation() {
  const classes = useStyles();
  return (
    <MKBox component="section" py={5}>
      <Container>
        <Grid container alignItems="center" spacing={3}>
          <Grid item xs={12} md={5} mb={5}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              sx={{ mx: "auto", textAlign: "center" }}
              height={460}
            >
              <Paper elevation={24} className={classes.paper}>
                <Grid item xs={12} px={3}>
                  <MKTypography variant="h3" mt={3}>
                    Vous pilotez un réseau ?
                  </MKTypography>
                  <MKTypography variant="h5" color="text" py={2}>
                    Découvrez comment optimiser votre stratégie web to store,
                    vous comparer à vos concurrents et animer vos points de
                    vente grâce aux avis publiés par leurs clients sur Google :
                  </MKTypography>
                </Grid>
                <Grid item xs={12} px={3}>
                  <ListItem title="1. Positionnez-vous par rapport à vos concurrents">
                    {" "}
                  </ListItem>
                  <ListItem title="2. Suivez l’évolution des notes les plus récentes">
                    {" "}
                  </ListItem>
                  <ListItem title="3. Partagez ces résultats avec vos magasins">
                    {" "}
                  </ListItem>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          <Grid item xs={12} md={7} mb={7}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              sx={{ mx: "auto", textAlign: "center" }}
              height={460}
            >
              <Paper elevation={24} className={classes.paper}>
                <Grid item xs={12} px={3}>
                  <MKTypography variant="h3" mt={3}>
                    Vous analysez la satisfaction de vos clients et les
                    tendances / attentes du marché ?
                  </MKTypography>
                  <MKTypography variant="h5" color="text" py={2}>
                    LA VOIX DES CLIENTS est la solution idéale pour vous aider à
                    décrypter, parmi les dizaines de milliers d’avis publiés sur
                    Google, les thématiques abordées par vos clients et les
                    attentes du marché.
                  </MKTypography>
                </Grid>
                <Grid item xs={12} px={3}>
                  <ListItem title="1. Examinez les forces et faiblesses de votre réseau">
                    {" "}
                  </ListItem>
                  <ListItem title="2. Etudiez les attentes des consommateurs sur votre marché en intégrant les avis publiés sur vos concurrents">
                    {" "}
                  </ListItem>
                  <ListItem title="3. Suivez l’évolution des ressentis dans les thématiques qui comptent le plus pour vous">
                    {" "}
                  </ListItem>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Presentation;
