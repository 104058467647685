/**
=========================================================
* Otis Kit PRO - v2.0.0
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Otis Kit PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Navbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  6. The `component` key is used to store the component of its route.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
  8. The `description` key is used to define the description of
          a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
          you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/

// @mui material components
import Icon from "@mui/material/Icon";

// Pages
import OfferPage from "layouts/offer";
import Home from "layouts/home";
import ContactUs from "layouts/support/contact-us";
import Faq from "layouts/support/faq";
import Privacy from "layouts/support/privacy";
import Blog from "layouts/blog";
//import Classement from "layouts/classement";
import PressePage from "layouts/presse";

const routes = [
  {
    name: "accueil",
    icon: <Icon>dashboard</Icon>,
    columns: 2,
    rowsPerColumn: 1,
    href: "/home",
    collapse: [
      {
        name: "à propos de nous",
        collapse: [
          {
            name: "accueil",
            route: "/",
            component: <Home />,
          },
          {
            name: "notre offre",
            route: "/offer",
            component: <OfferPage />,
          },
          {
            name: "lauréats 2023",
            route: "/laureats",
            component: <Blog />,
          },
          /*{
            name: "classements",
            route: "/classements",
            component: <Classement />,
          },*/
          {
            name: "presse",
            route: "/presse",
            component: <PressePage />,
          },
        ],
      },
      {
        name: "support",
        collapse: [
          {
            name: "nous contacter",
            route: "/support/contact-us",
            component: <ContactUs />,
          },
          {
            name: "f.a.q.",
            route: "/support/faq",
            component: <Faq />,
          },
          {
            name: "confidentialité",
            route: "/support/privacy",
            component: <Privacy />,
          },
        ],
      },
    ],
  },
  {
    name: "notre offre",
    icon: <Icon>dashboard</Icon>,
    columns: 2,
    rowsPerColumn: 1,
    href: "/offer",
    collapse: [
      {
        name: "à propos de nous",
        collapse: [
          {
            name: "accueil",
            route: "/",
            component: <Home />,
          },
          {
            name: "notre offre",
            route: "/offer",
            component: <OfferPage />,
          },
          {
            name: "lauréats 2023",
            route: "/laureats",
            component: <Blog />,
          },
          /*{
            name: "classements",
            route: "/classements",
            component: <Classement />,
          },*/
          {
            name: "presse",
            route: "/presse",
            component: <PressePage />,
          },
        ],
      },
      {
        name: "support",
        collapse: [
          {
            name: "nous contacter",
            route: "/support/contact-us",
            component: <ContactUs />,
          },
          {
            name: "f.a.q.",
            route: "/support/faq",
            component: <Faq />,
          },
          {
            name: "confidentialité",
            route: "/support/privacy",
            component: <Privacy />,
          },
        ],
      },
    ],
  },
  {
    name: "lauréats 2023",
    icon: <Icon>dashboard</Icon>,
    columns: 2,
    rowsPerColumn: 1,
    href: "/laureats",
    collapse: [
      {
        name: "à propos de nous",
        collapse: [
          {
            name: "accueil",
            route: "/",
            component: <Home />,
          },
          {
            name: "notre offre",
            route: "/offer",
            component: <OfferPage />,
          },
          {
            name: "lauréats 2023",
            route: "/laureats",
            component: <Blog />,
          },
          /*{
            name: "classements",
            route: "/classements",
            component: <Classement />,
          },*/
          {
            name: "presse",
            route: "/presse",
            component: <PressePage />,
          },
        ],
      },
      {
        name: "support",
        collapse: [
          {
            name: "nous contacter",
            route: "/support/contact-us",
            component: <ContactUs />,
          },
          {
            name: "f.a.q.",
            route: "/support/faq",
            component: <Faq />,
          },
          {
            name: "confidentialité",
            route: "/support/privacy",
            component: <Privacy />,
          },
        ],
      },
    ],
  },
  /*{
    name: "classements",
    icon: <Icon>dashboard</Icon>,
    columns: 2,
    rowsPerColumn: 1,
    href: "/classements",
    collapse: [
      {
        name: "à propos de nous",
        collapse: [
          {
            name: "accueil",
            route: "/",
            component: <Home />,
          },
          {
            name: "notre offre",
            route: "/offer",
            component: <OfferPage />,
          },
          {
            name: "lauréats 2023",
            route: "/laureats",
            component: <Blog />,
          },
          /*{
            name: "classements",
            route: "/classements",
            component: <Classement />,
          },
          {
            name: "presse",
            route: "/presse",
            component: <PressePage />,
          },
        ],
      },
      {
        name: "support",
        collapse: [
          {
            name: "nous contacter",
            route: "/support/contact-us",
            component: <ContactUs />,
          },
          {
            name: "f.a.q.",
            route: "/support/faq",
            component: <Faq />,
          },
          {
            name: "confidentialité",
            route: "/support/privacy",
            component: <Privacy />,
          },
        ],
      },
    ],
  },*/
  {
    name: "presse",
    icon: <Icon>dashboard</Icon>,
    columns: 2,
    rowsPerColumn: 1,
    href: "/presse",
    collapse: [
      {
        name: "à propos de nous",
        collapse: [
          {
            name: "accueil",
            route: "/",
            component: <Home />,
          },
          {
            name: "notre offre",
            route: "/offer",
            component: <OfferPage />,
          },
          {
            name: "lauréats 2023",
            route: "/laureats",
            component: <Blog />,
          },
          /*{
            name: "classements",
            route: "/classements",
            component: <Classement />,
          },*/
          {
            name: "presse",
            route: "/presse",
            component: <PressePage />,
          },
        ],
      },
      {
        name: "support",
        collapse: [
          {
            name: "nous contacter",
            route: "/support/contact-us",
            component: <ContactUs />,
          },
          {
            name: "f.a.q.",
            route: "/support/faq",
            component: <Faq />,
          },
          {
            name: "confidentialité",
            route: "/support/privacy",
            component: <Privacy />,
          },
        ],
      },
    ],
  },
  {
    name: "nous contacter",
    icon: <Icon>dashboard</Icon>,
    columns: 2,
    rowsPerColumn: 1,
    href: "/support/contact-us",
    collapse: [
      {
        name: "à propos de nous",
        collapse: [
          {
            name: "accueil",
            route: "/",
            component: <Home />,
          },
          {
            name: "notre offre",
            route: "/offer",
            component: <OfferPage />,
          },
          {
            name: "lauréats 2023",
            route: "/laureats",
            component: <Blog />,
          },
          /*{
            name: "classements",
            route: "/classements",
            component: <Classement />,
          },*/
          {
            name: "presse",
            route: "/presse",
            component: <PressePage />,
          },
        ],
      },
      {
        name: "support",
        collapse: [
          {
            name: "nous contacter",
            route: "/support/contact-us",
            component: <ContactUs />,
          },
          {
            name: "f.a.q.",
            route: "/support/faq",
            component: <Faq />,
          },
          {
            name: "confidentialité",
            route: "/support/privacy",
            component: <Privacy />,
          },
        ],
      },
    ],
  },
  {
    name: "f.a.q.",
    icon: <Icon>dashboard</Icon>,
    columns: 2,
    rowsPerColumn: 1,
    href: "/support/faq",
    collapse: [
      {
        name: "à propos de nous",
        collapse: [
          {
            name: "accueil",
            route: "/",
            component: <Home />,
          },
          {
            name: "notre offre",
            route: "/offer",
            component: <OfferPage />,
          },
          {
            name: "lauréats 2023",
            route: "/laureats",
            component: <Blog />,
          },
          /*{
            name: "classements",
            route: "/classements",
            component: <Classement />,
          },*/
          {
            name: "presse",
            route: "/presse",
            component: <PressePage />,
          },
        ],
      },
      {
        name: "support",
        collapse: [
          {
            name: "nous contacter",
            route: "/support/contact-us",
            component: <ContactUs />,
          },
          {
            name: "f.a.q.",
            route: "/support/faq",
            component: <Faq />,
          },
          {
            name: "confidentialité",
            route: "/support/privacy",
            component: <Privacy />,
          },
        ],
      },
    ],
  },
  {
    name: "confidentialité",
    icon: <Icon>dashboard</Icon>,
    columns: 2,
    rowsPerColumn: 1,
    href: "/support/privacy",
    collapse: [
      {
        name: "à propos de nous",
        collapse: [
          {
            name: "accueil",
            route: "/",
            component: <Home />,
          },
          {
            name: "notre offre",
            route: "/offer",
            component: <OfferPage />,
          },
          {
            name: "lauréats 2023",
            route: "/laureats",
            component: <Blog />,
          },
          /*{
            name: "classements",
            route: "/classements",
            component: <Classement />,
          },*/
          {
            name: "presse",
            route: "/presse",
            component: <PressePage />,
          },
        ],
      },
      {
        name: "support",
        collapse: [
          {
            name: "nous contacter",
            route: "/support/contact-us",
            component: <ContactUs />,
          },
          {
            name: "f.a.q.",
            route: "/support/faq",
            component: <Faq />,
          },
          {
            name: "confidentialité",
            route: "/support/privacy",
            component: <Privacy />,
          },
        ],
      },
    ],
  },
];

export default routes;
