import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Otis Kit PRO examples
import Footer from "examples/Footers/CenteredFooter";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// HelpCenter page components
import FaqCollapse from "pages/Support/Faq/FaqCollapse";

// Routes
import footerRoutes from "footer.routes";
import routes from "routes";

function Faq() {
  const [collapse, setCollapse] = useState(false);

  return (
    <>
      <MKBox variant="gradient" bgColor="dark" shadow="sm">
        <DefaultNavbar routes={routes} transparent relative light center />
      </MKBox>
      <MKBox component="section" pt={20} pb={12}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Card>
                <MKBox
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  p={3}
                  mt={-3}
                  mx={2}
                >
                  <MKTypography variant="h3" color="white">
                    FAQ
                  </MKTypography>
                  <MKTypography variant="body2" color="white" opacity={0.8}>
                    Dernières modifications : 10 Avril 2022
                  </MKTypography>
                </MKBox>
                <MKBox p={6}>
                  <MKTypography variant="h5" my={3}>
                    Définition
                  </MKTypography>
                  <FaqCollapse
                    title="A quoi correspondent les avis Google ?"
                    open={collapse === 1}
                    onClick={() =>
                      collapse === 1 ? setCollapse(false) : setCollapse(1)
                    }
                  >
                    Il s&apos;agit des appréciations rédigées par des clients
                    pour témoigner de leur expérience vis-à-vis d&apos;un
                    commerce ou d&apos;un prestataire. Les avis sont
                    systématiquement accompagnés d&apos;une note symbolisée par
                    des étoiles, dont l&apos;échelle va de 1 (mauvais) à 5
                    (parfait). La note moyenne qui apparait à côté de la marque
                    est le résultat de l&apos;addition des notes attribuées par
                    l&apos;ensemble des « votants », divisé par le nombre
                    d&apos;avis publiés sur l&apos;établissement considéré.
                  </FaqCollapse>
                  <FaqCollapse
                    title="Quel est l'intérêt de ces notes ?"
                    open={collapse === 2}
                    onClick={() =>
                      collapse === 2 ? setCollapse(false) : setCollapse(2)
                    }
                  >
                    La puissance des notations liées à l&apos;expérience client
                    n&apos;est plus à démontrer. Sur une plateforme comme Google
                    c&apos;est un formidable outil de communication pour les
                    commerçants qui reçoivent des suffrages élogieux. A
                    l&apos;inverse, les boutiques dont la moyenne est faible
                    perdent forcément des opportunités de ventes… C’est donc un
                    moment clé du « web-to-store », car le consommateur évalue
                    immédiatement l’intérêt de choisir un commerçant ou non.
                    <br />
                    L’enjeu est crucial pour les enseignes en réseau ; chaque
                    établissement est un représentant de la marque sur sa zone
                    de chalandise. Pour le responsable du réseau ou le directeur
                    commercial, 3 dimensions majeures sont à prendre en compte
                    avec ces notes :
                    <MKBox component="ul" my={3} ml={6}>
                      <MKBox component="li">
                        <MKTypography
                          variant="body3"
                          color="text"
                          fontWeight="regular"
                        >
                          <MKTypography
                            variant="body3"
                            color="text"
                            fontWeight="bold"
                          >
                            La comparaison interne.
                          </MKTypography>{" "}
                          Quels sont les points de vente qui tirent leur épingle
                          du jeu, et a contrario ceux qui sont en retard par
                          rapport à la moyenne générale du réseau ? Ce mapping
                          est un moyen particulièrement efficace pour animer
                          votre réseau, encourager ou sensibiliser les
                          établissements qui doivent progresser, créer des
                          classements ou des podiums…
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography
                          variant="body2"
                          color="text"
                          fontWeight="regular"
                        >
                          <MKTypography
                            variant="body3"
                            color="text"
                            fontWeight="bold"
                          >
                            La comparaison avec la concurrence.
                          </MKTypography>{" "}
                          Les réseaux adverses et les indépendants sont aussi
                          évalués ; connaitre leur position vous permet de vous
                          situer sur le marché. Nos clients qui recueillent les
                          meilleures notes choisissent souvent de communiquer,
                          tant en interne qu&apos;en externe, pour mettre en
                          valeur leur performance.
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography
                          variant="body2"
                          color="text"
                          fontWeight="regular"
                        >
                          <MKTypography
                            variant="body3"
                            color="text"
                            fontWeight="bold"
                          >
                            L&apos;évolution dans le temps.
                          </MKTypography>{" "}
                          Au-delà des instantanés, l&apos;évolution des notes
                          est un film qui révèle les améliorations mais aussi
                          les contre-performances sur une période donnée, tant
                          pour votre réseau que pour ceux des concurrents.
                          Mesurer ces mouvements permet de déceler la
                          persistance d&apos;éventuelles lacunes, ce qu&apos;une
                          photo à l&apos;instant T n&apos;illustre pas.
                        </MKTypography>
                      </MKBox>
                    </MKBox>
                  </FaqCollapse>
                  <FaqCollapse
                    title="Quelle est la différence entre la note Google et la note pondérée par VASANO utilisée pour un classement ?"
                    open={collapse === 3}
                    onClick={() =>
                      collapse === 3 ? setCollapse(false) : setCollapse(3)
                    }
                  >
                    La note Google correspond à la moyenne de l&apos;ensemble
                    des évaluations attribuées à un établissement, quelle que
                    soit leur antériorité. Pour réaliser un classement, et donc
                    hiérarchiser des places selon un résultat, nous considérons
                    que deux paramètres supplémentaires doivent être pris en
                    compte :
                    <MKBox component="ul" my={3} ml={6}>
                      <MKBox component="li">
                        <MKTypography
                          variant="body3"
                          color="text"
                          fontWeight="regular"
                        >
                          Comparer le nombre de votants d’un magasin ou d’un
                          réseau au nombre moyen de son secteur d’activité.
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography
                          variant="body2"
                          color="text"
                          fontWeight="regular"
                        >
                          Comparer la note d’un magasin ou d’un réseau à la
                          moyenne de son secteur d’activité.
                        </MKTypography>
                      </MKBox>
                    </MKBox>
                    Cette méthode n’a qu’un objectif : créer de l’équité. Un
                    établissement qui a une note moyenne égale à 5 avec 2 avis
                    sera moins bien classé qu&apos;un établissement noté 4,8 qui
                    a reçu 100 avis. A l&apos;inverse, un établissement qui a
                    une note moyenne de 2,5 avec 2 avis sera mieux classé
                    qu&apos;un établissement noté 2,8 avec 50 avis.
                    <br />
                    Le mode de calcul du score VASANO est disponible sur cette
                    page :
                    https://vasano.fr/static/media/score_vasano.7622fe80.pdf
                  </FaqCollapse>
                  <MKTypography variant="h5" my={3}>
                    Tarification
                  </MKTypography>
                  <FaqCollapse
                    title="Combien d'avis un établissement recueille-t-il en moyenne ?"
                    open={collapse === 4}
                    onClick={() =>
                      collapse === 4 ? setCollapse(false) : setCollapse(4)
                    }
                  >
                    Le nombre d&apos;avis varie considérablement selon la
                    popularité de l&apos;enseigne, son activité, sa localisation
                    et ses efforts pour susciter les appréciations. Certains
                    points de vente recueillent une centaine d&apos;avis chaque
                    année alors que d&apos;autres en totalisent à peine 10 en 3
                    ans…
                  </FaqCollapse>
                  <FaqCollapse
                    title="Comment VASANO facture-t-il ses prestations ?"
                    open={collapse === 5}
                    onClick={() =>
                      collapse === 5 ? setCollapse(false) : setCollapse(5)
                    }
                  >
                    <MKTypography
                      variant="body2"
                      color="text"
                      fontWeight="regular"
                    >
                      Le travail de VASANO consiste à effectuer une veille sur
                      le périmètre que vous avez déterminé :
                      <MKBox component="ul" my={3} ml={6}>
                        <MKBox component="li">
                          <MKTypography
                            variant="body2"
                            color="text"
                            fontWeight="regular"
                          >
                            la liste des points de vente à suivre (internes et
                            concurrents), éventuellement délimités dans une zone
                            géographique
                          </MKTypography>
                        </MKBox>
                        <MKBox component="li">
                          <MKTypography
                            variant="body2"
                            color="text"
                            fontWeight="regular"
                          >
                            la fréquence des relevés (permanente, trimestrielle
                            ou annuelle)
                          </MKTypography>
                        </MKBox>
                      </MKBox>
                      Le prix est donc fixé en fonction du volume de sites à
                      surveiller et du nombre de relevés à réaliser. Des frais
                      fixes sont perçus au démarrage du process, notamment pour
                      la réalisation du tableau de bord que vous utiliserez.
                    </MKTypography>
                  </FaqCollapse>
                  <FaqCollapse
                    title="Qu’est-ce qu’un audit chez VASANO SOLUTIONS ?"
                    open={collapse === 6}
                    onClick={() =>
                      collapse === 6 ? setCollapse(false) : setCollapse(6)
                    }
                  >
                    Nous réalisons 2 sortes d’études :
                    <MKBox component="ul" my={3} ml={6}>
                      <MKBox component="li">
                        <MKTypography
                          variant="body2"
                          color="text"
                          fontWeight="regular"
                        >
                          L’analyse des fiches Google Business Profile (« GBP »)
                          de votre réseau.
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography
                          variant="body2"
                          color="text"
                          fontWeight="regular"
                        >
                          L’analyse sémantique matricielle des avis publiés par
                          vos clients.
                        </MKTypography>
                      </MKBox>
                    </MKBox>
                    Dans le premier cas, la prestations se décompose en 3 volets
                    :
                    <MKBox component="ul" my={3} ml={6}>
                      <MKBox component="li">
                        <MKTypography
                          variant="body2"
                          color="text"
                          fontWeight="regular"
                        >
                          retrouver l’ensemble des fiches Google de vos points
                          de vente,
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography
                          variant="body2"
                          color="text"
                          fontWeight="regular"
                        >
                          identifier les sites qui se déclarent affiliés à votre
                          réseau mais qui ne figurent pas dans votre
                          référentiel,
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography
                          variant="body2"
                          color="text"
                          fontWeight="regular"
                        >
                          évaluer les fiches GBP de votre réseau à partir de
                          critères qualitatifs. Exemple :
                          <MKBox component="ul" my={3} ml={6}>
                            <MKBox component="li">
                              <MKTypography
                                variant="body2"
                                color="text"
                                fontWeight="regular"
                              >
                                La photo montre-t-elle le site, l&apos;enseigne,
                                la devanture ou l&apos;intérieur des locaux de
                                l&apos;affilié ?
                              </MKTypography>
                            </MKBox>
                            <MKBox component="li">
                              <MKTypography
                                variant="body2"
                                color="text"
                                fontWeight="regular"
                              >
                                La photo est-elle valorisante ?
                              </MKTypography>
                            </MKBox>
                            <MKBox component="li">
                              <MKTypography
                                variant="body2"
                                color="text"
                                fontWeight="regular"
                              >
                                L&apos;enseigne du réseau est-elle indiquée sur
                                la fiche de l&apos;affilié ?
                              </MKTypography>
                            </MKBox>
                            <MKBox component="li">
                              <MKTypography
                                variant="body2"
                                color="text"
                                fontWeight="regular"
                              >
                                La marque d&apos;un autre réseau est-elle
                                inscrite sur la fiche ?
                              </MKTypography>
                            </MKBox>
                            <MKBox component="li">
                              <MKTypography
                                variant="body2"
                                color="text"
                                fontWeight="regular"
                              >
                                L&apos;activité inscrite sur la fiche de
                                l&apos;affilié correspond-elle à celle de son
                                réseau ?
                              </MKTypography>
                            </MKBox>
                            <MKBox component="li">
                              <MKTypography
                                variant="body2"
                                color="text"
                                fontWeight="regular"
                              >
                                L&apos;adresse est-elle complète ?
                              </MKTypography>
                            </MKBox>
                            <MKBox component="li">
                              <MKTypography
                                variant="body2"
                                color="text"
                                fontWeight="regular"
                              >
                                Le téléphone est-il mentionné ?
                              </MKTypography>
                            </MKBox>
                            <MKBox component="li">
                              <MKTypography
                                variant="body2"
                                color="text"
                                fontWeight="regular"
                              >
                                Les horaires d&apos;ouverture sont-ils spécifiés
                                ?
                              </MKTypography>
                            </MKBox>
                            <MKBox component="li">
                              <MKTypography
                                variant="body2"
                                color="text"
                                fontWeight="regular"
                              >
                                Un site web est-il indiqué ?
                              </MKTypography>
                            </MKBox>
                            <MKBox component="li">
                              <MKTypography
                                variant="body2"
                                color="text"
                                fontWeight="regular"
                              >
                                Etc.
                              </MKTypography>
                            </MKBox>
                          </MKBox>
                        </MKTypography>
                      </MKBox>
                    </MKBox>
                    Le principe est d’attribuer une note sur 10 pour obtenir une
                    vision claire de l’apparence des fiches des affiliés à votre
                    réseau. Pour l’analyse sémantique matricielle, il s’agit de
                    regrouper par thème les commentaires publiés par vos clients
                    en les répartissant selon 3 niveaux d’appréciation : «
                    négatif », « neutre » et « positif ».
                    <br />
                    <br />
                    L’analyse s’appuie sur 3 critères :
                    <MKBox component="ul" my={3} ml={6}>
                      <MKBox component="li">
                        <MKTypography
                          variant="body2"
                          color="text"
                          fontWeight="regular"
                        >
                          L’antériorité des avis à prendre en compte
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography
                          variant="body2"
                          color="text"
                          fontWeight="regular"
                        >
                          Le niveau d’agrégation (par magasin, par département,
                          par région ?)
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography
                          variant="body2"
                          color="text"
                          fontWeight="regular"
                        >
                          Le nombre de thèmes à analyser (ex : « propreté », «
                          accueil », « choix », « horaires », « caisses », etc.)
                        </MKTypography>
                      </MKBox>
                    </MKBox>
                  </FaqCollapse>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Résultats
                  </MKTypography>
                  <FaqCollapse
                    title="Comment sont présentés les résultats de la veille ?"
                    open={collapse === 7}
                    onClick={() =>
                      collapse === 7 ? setCollapse(false) : setCollapse(7)
                    }
                  >
                    Le livrable est un tableau de bord réalisé avec Power BI de
                    Microsoft. Vous pouvez examiner les données grâce à de
                    nombreuses fonctionnalités (filtres, sélection de critères,
                    etc.). Si vous souhaitez partager votre dashboard en donnant
                    accès à d&apos;autres collaborateurs, nous pouvons leur
                    créer un accès personnalisé moyennant une contribution de
                    365 euros HT par an / individu. Cette participation inclut
                    l&apos;application d&apos;éventuelles restrictions pour
                    limiter leur périmètre de consultation / manipulation, par
                    exemple en les restreignant sur une région ou un groupe
                    d&apos;établissements.
                  </FaqCollapse>
                  <FaqCollapse
                    title="Comment sont actualisées les notes Google dans la veille VASANO ?"
                    open={collapse === 8}
                    onClick={() =>
                      collapse === 8 ? setCollapse(false) : setCollapse(8)
                    }
                  >
                    Il existe 2 fréquences de mises à jour
                    <MKBox component="ul" my={3} ml={6}>
                      <MKBox component="li">
                        <MKTypography
                          variant="body3"
                          color="text"
                          fontWeight="regular"
                        >
                          « ON DEMAND » Cet instantané fournit les résultats
                          arrêtés à la date définie, mais ne permet pas de
                          visualiser une évolution par rapport à une date
                          antérieure.
                        </MKTypography>
                      </MKBox>
                      <MKBox component="li">
                        <MKTypography
                          variant="body2"
                          color="text"
                          fontWeight="regular"
                        >
                          « ITERATIVE » Comme son nom l&apos;indique, cette
                          formule permet au client de visualiser en continu les
                          variations qui impactent son panel.
                        </MKTypography>
                      </MKBox>
                    </MKBox>
                  </FaqCollapse>
                  <FaqCollapse
                    title="Quelle est la durée d'engagement pour une veille ?"
                    open={collapse === 9}
                    onClick={() =>
                      collapse === 9 ? setCollapse(false) : setCollapse(9)
                    }
                  >
                    Il n&apos;y en a pas pour la formule ON DEMAND. Le rapport
                    est livré sans mise à jour ultérieure. L’offre ITERATIVE
                    prévoit un engagement d&apos;un an avec tacite reconduction.
                    La non-reconduction est assortie d&apos;un préavis de 3
                    mois.
                  </FaqCollapse>
                  <FaqCollapse
                    title="Comment puis-je m'assurer de l'exhaustivité des informations collectées par VASANO ?"
                    open={collapse === 10}
                    onClick={() =>
                      collapse === 10 ? setCollapse(false) : setCollapse(10)
                    }
                  >
                    Pour votre réseau, vous pouvez nous fournir la liste de vos
                    établissements et nous les rechercherons intégralement
                    (enseigne, adresse exacte). Le rapprochement entre votre
                    liste et notre système de recherche est une opération
                    d&apos;appariement qui coûte 1 € HT par adresse. Si vous ne
                    disposez pas du fichier des implantations de vos sites ou de
                    ceux de vos concurrents, la recherche des sites à surveiller
                    dépendra de ces 3 critères de référencement : le nom sous
                    lequel ils sont référencés, puis l&apos;activité sous
                    laquelle ils sont inscrits et enfin la précision de leurs
                    coordonnées postales. Si certains magasins échappent à notre
                    recensement, il vous suffit de nous le signaler et nous les
                    ajouterons dans le plus bref délai.
                  </FaqCollapse>
                  <FaqCollapse
                    title="Comment faire pour améliorer la moyenne de mon réseau ?"
                    open={collapse === 11}
                    onClick={() =>
                      collapse === 11 ? setCollapse(false) : setCollapse(11)
                    }
                  >
                    Deux actions en profondeur doivent être simultanément
                    accomplies : veiller à sans cesse favoriser
                    l&apos;expérience clients (accueil, qualité de service,
                    diversité des offres, disponibilité, prise en compte des
                    réclamations…) et inciter les clients satisfaits à témoigner
                    sur Google. Les collaborateurs des établissements sur le
                    terrain sont en première ligne, mais la politique du réseau
                    est déterminante pour les encourager et leur donner les
                    moyens d&apos;y parvenir…
                  </FaqCollapse>
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox>
        <Footer content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Faq;
