/*
=========================================================
* Otis Kit PRO - v2.0.0
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
// import Stack from "@mui/material/Stack";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import image from "assets/images/max.jpg";

function Testimonials() {
  return (
    <MKBox component="section" position="relative" py={10}>
      <Container sx={{ maxWidth: "100% !important" }}>
        <Grid container item xs={10} sx={{ mx: "auto" }}>
          <MKBox
            variant="gradient"
            bgColor="dark"
            borderRadius="lg"
            width="100%"
            py={6}
          >
            <Grid container>
              <Grid
                item
                xs={12}
                md={12}
                xl={4}
                sx={{ position: "relative", px: 6 }}
              >
                <MKBox
                  component="img"
                  src={image}
                  alt="image"
                  borderRadius="md"
                  maxWidth="300px"
                  width="100%"
                  position="relative"
                  mt={-12}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                xl={4}
                sx={{
                  position: "relative",
                  px: { xs: 6, md: 2 },
                  mt: { xs: 3, md: 0 },
                  my: { xs: 0, md: "auto" },
                  color: ({ palette: { white } }) => white.main,
                }}
              >
                <Icon fontSize="large">format_quote</Icon>
                <MKTypography
                  variant="body1"
                  color="white"
                  mb={2}
                  sx={{ fontSize: "1.125rem" }}
                >
                  Google incite de plus en plus les internautes à évaluer les
                  commerces et les lieux de consommation. Sa volonté est
                  clairement de faire émerger les influenceurs locaux. Notre
                  rôle est de recueillir et d’analyser leurs avis.
                </MKTypography>
                <MKTypography variant="button" fontWeight="bold" color="white">
                  Maximilien DAVID -{" "}
                  <MKTypography variant="caption" color="white">
                    Co-Founder
                  </MKTypography>
                </MKTypography>
              </Grid>
              <Grid item xs={1} />
              <Grid
                item
                xs={12}
                xl={2}
                sx={{
                  px: { xs: 6, xl: 0 },
                  my: { xs: 0, xl: "auto" },
                  lineHeight: 1,
                }}
              >
                <MKTypography
                  variant="h3"
                  color="info"
                  mt={{ xs: 6, xl: 0 }}
                  mb={1}
                >
                  33%
                </MKTypography>
                <MKTypography
                  component="p"
                  variant="button"
                  color="white"
                  opacity={0.8}
                  mb={2}
                >
                  C&apos;est le pourcentage d&apos;augmentation des avis données
                  sur google en 2021
                </MKTypography>
                <MKTypography
                  component="a"
                  href="/offer"
                  variant="button"
                  color="white"
                  fontWeight="regular"
                  sx={{
                    width: "max-content",
                    display: "flex",
                    alignItems: "center",

                    "& .material-icons-round": {
                      fontSize: "1.125rem",
                      transform: `translateX(3px)`,
                      transition:
                        "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                    },

                    "&:hover .material-icons-round, &:focus .material-icons-round":
                      {
                        transform: `translateX(6px)`,
                      },
                  }}
                >
                  Découvrez notre produit
                  <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                </MKTypography>
              </Grid>
            </Grid>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Testimonials;
