/*
=========================================================
* Otis Kit PRO - v2.0.0
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// import Header from "examples/Navbars/CustomNavbar/Header";
import Footer from "examples/Footers/CenteredFooter";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Presentation page sections
import Counters from "pages/Home/sections/Counters";
import Information from "pages/Home/sections/Information";
import Testimonials from "pages/Home/sections/Testimonials";
import Presentation from "pages/Home/sections/Presentation";

import footerRoutes from "footer.routes";

import routes from "routes";

// Images
import bgImage from "assets/images/bg-home-header.jpg";

function Home() {
  return (
    <>
      <MKBox variant="gradient" bgColor="dark" shadow="sm">
        <DefaultNavbar routes={routes} transparent relative light center />
      </MKBox>
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
          minHeight: 550,
        }}
      >
        <Container>
          <Grid container item justifyContent="center" spacing={5} mb={2}>
            <Grid item md={7}>
              <MKTypography
                variant="h1"
                color="white"
                textAlign="center"
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("xl")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                Les notes et avis Google de vos magasins et de vos concurrents
                enfin disponibles en un clic !
              </MKTypography>
              <MKTypography
                variant="body1"
                color="white"
                textAlign="center"
                px={2}
                mt={2}
              >
                La consultation de notre benchmark ne nécessite aucune connexion
                avec vos systèmes d’informations ni échanges de données. Vous
                suivez à tout moment les indicateurs de la satisfaction client
                sur vos marchés (national ou régional) et partagez ces résultats
                avec les membres de votre réseau.
                <br />
                <br />
                <MKButton
                  variant="gradient"
                  color="info"
                  href="offer"
                  size="large"
                >
                  en savoir plus...
                </MKButton>
              </MKTypography>
            </Grid>
            <Grid item sm={12} md={5} mt={5}>
              <iframe
                src="https://player.vimeo.com/video/716031919?h=c9c2c8fa23"
                width="100%"
                height="100%"
                scrolling="no"
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
                title="Vasano3D"
              />
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          mx: { xs: 2, lg: 3 },
          mt: -4,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
            rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Counters />
        <Presentation />
        <Divider />
        <Information />
        <Testimonials />
      </Card>
      <MKBox>
        <Footer content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Home;
