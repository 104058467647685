/*
=========================================================
* Otis Kit PRO - v2.0.0
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import Footer from "examples/Footers/CenteredFooter";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

import footerRoutes from "footer.routes";

import routes from "routes";

// Images
import bgImage from "assets/images/bg-blog.jpg";
import nextStage from "assets/images/presse/nextstage.png";
import decisionAtelier from "assets/images/presse/decisionAtelier.PNG";
import human from "assets/images/presse/human.PNG";
import lcl from "assets/images/presse/lcl.PNG";
import zepros from "assets/images/presse/zepros.PNG";
import j2r from "assets/images/presse/j2r.PNG";
import telegramme from "assets/images/presse/telegramme.PNG";
import altavia from "assets/images/presse/altavia.PNG";
import universHabitat from "assets/images/presse/universHabitat.PNG";
import pleineVie from "assets/images/presse/pleineVie.PNG";
import kw from "assets/images/presse/kw.PNG";
import linkedIn from "assets/images/presse/linkedIn.png";
import twitter from "assets/images/presse/twitter.png";
import classement from "assets/images/presse/classement.png";
import topCom from "assets/images/presse/topCom.png";
import itr from "assets/images/presse/itr.png";
import jdn from "assets/images/presse/jdn.png";
import digitalmag from "assets/images/presse/digitalmag.png";
import referencePareBrise from "assets/images/presse/reference-pare-brise.png";
import leMoniteur from "assets/images/presse/le-moniteur.png";
import j2r2 from "assets/images/presse/j2r-2.png";
import j2r3 from "assets/images/presse/j2r-3.png";
import lassuranceEnMouvement from "assets/images/presse/lassurance-en-mouvement.png";
import mediacom from "assets/images/presse/mediacom.png";
import ecommerceMag from "assets/images/presse/ecommerce-mag.png";
import officielDeLaFranchise from "assets/images/presse/officiel-de-la-franchise.png";
import largusDeLassurance from "assets/images/presse/largus-de-lassurance.png";
import jafInfo from "assets/images/presse/jaf-info.png";
import paSport from "assets/images/presse/pa-sport.png";
import louie from "assets/images/presse/louie.png";
import femina from "assets/images/presse/femina.png";
import cosmopolitan from "assets/images/presse/cosmopolitan.png";
import marieFrance from "assets/images/presse/marie-france.png";
import zepros2 from "assets/images/presse/zepros2.png";
import meubleInfos from "assets/images/presse/meuble-infos.png";
import universHabitat2 from "assets/images/presse/univers-habitat.png";
import batipresse from "assets/images/presse/batipresse.png";
import batipole from "assets/images/presse/batipole.png";
import choisirSaFranchise from "assets/images/presse/choisir-sa-franchise.png";


function Presse() {
  return (
    <>
      <MKBox variant="gradient" bgColor="dark" shadow="sm">
        <DefaultNavbar routes={routes} transparent relative light center />
      </MKBox>
      <MKBox
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
          minHeight: 800,
        }}
      >
        <Container>
          <MKTypography
            variant="h1"
            color="white"
            textAlign="center"
            sx={({ breakpoints, typography: { size } }) => ({
              [breakpoints.down("xl")]: {
                fontSize: size["3xl"],
              },
            })}
            mt={5}
          >
            La presse en parle
          </MKTypography>
          <Divider />
          <Grid container mt={3} mb={15} justifyContent="center" spacing={5}>
            <MKBox
              component="img"
              src={classement}
              alt="image"
              width="75%"
              height="75%"
            />
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { grey }, functions: { rgba } }) =>
            rgba(grey[300], 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox component="section" mb={3}>
          <Grid
            container
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            sx={{ mx: "auto", textAlign: "center" }}
            py={5}
          >
            <Grid item xs={12} md={6} px={10}>
              <Card>
                <Grid item m={5}>
                  <MKBox
                    component="img"
                    src={j2r3}
                    alt="image"
                    width="75%"
                    height="75%"
                  />
                  <MKTypography
                    variant="body2"
                    fontWeight="bold"
                    color="text"
                    mt={3}
                  >
                    J2R -
                  </MKTypography>{" "}
                  <MKTypography
                    variant="body2"
                    color="text"
                    textAlign="justify"
                  >
                    <MKTypography variant="caption" color="text">
                      <a
                        href="https://j2rauto.com/reseaux/satisfaction-client-quelles-sont-les-enseignes-plebiscitees-dans-lautomobile/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Lien vers l&apos;article
                      </a>
                    </MKTypography>
                  </MKTypography>
                  <MKTypography variant="caption" color="text">
                    <a href="/classements">-&gt; Voir le classement</a>
                  </MKTypography>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} px={10}>
              <Card>
                <Grid item m={5}>
                  <MKBox
                    component="img"
                    src={lassuranceEnMouvement}
                    alt="image"
                    width="75%"
                    height="75%"
                  />
                  <MKTypography
                    variant="body2"
                    fontWeight="bold"
                    color="text"
                    mt={3}
                  >
                    L'assurance en mouvement -
                  </MKTypography>{" "}
                  <MKTypography
                    variant="body2"
                    color="text"
                    textAlign="justify"
                  >
                    <MKTypography variant="caption" color="text">
                      <a
                        href="https://www.lassuranceenmouvement.com/2024/01/18/agences-assurance-allianz-enseigne-la-mieux-notee-sur-google/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Lien vers l&apos;article
                      </a>
                    </MKTypography>
                  </MKTypography>
                  <MKTypography variant="caption" color="text">
                    <a href="/classements">-&gt; Voir le classement</a>
                  </MKTypography>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </MKBox>
        <MKBox component="section" mb={3}>
          <Grid
            container
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            sx={{ mx: "auto", textAlign: "center" }}
            py={5}
          >
            <Grid item xs={12} md={6} px={10}>
              <Card>
                <Grid item m={5}>
                  <MKBox
                    component="img"
                    src={largusDeLassurance}
                    alt="image"
                    width="75%"
                    height="75%"
                  />
                  <MKTypography
                    variant="body2"
                    fontWeight="bold"
                    color="text"
                    mt={3}
                  >
                    L'Argus de l'Assurance -
                  </MKTypography>{" "}
                  <MKTypography
                    variant="body2"
                    color="text"
                    textAlign="justify"
                  >
                    <MKTypography variant="caption" color="text">
                      <a
                        href="https://www.argusdelassurance.com/les-assureurs/un-assureur-occupe-la-premiere-place-parmi-les-agences-les-mieux-notees.230071"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Lien vers l&apos;article
                      </a>
                    </MKTypography>
                  </MKTypography>
                  <MKTypography variant="caption" color="text">
                    <a href="/classements">-&gt; Voir le classement</a>
                  </MKTypography>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} px={10}>
              <Card>
                <Grid item m={5}>
                  <MKBox
                    component="img"
                    src={mediacom}
                    alt="image"
                    width="75%"
                    height="75%"
                  />
                  <MKTypography
                    variant="body2"
                    fontWeight="bold"
                    color="text"
                    mt={3}
                  >
                    Média Com' -
                  </MKTypography>{" "}
                  <MKTypography
                    variant="body2"
                    color="text"
                    textAlign="justify"
                  >
                    <MKTypography variant="caption" color="text">
                      <a
                        href="https://lemediacom.com/allianz-france-a-la-premiere-place-du-classement-des-agences-dassurance-les-mieux-notees-sur-google-en-2023-dans-la-categorie-agences-dassurance/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Lien vers l&apos;article
                      </a>
                    </MKTypography>
                  </MKTypography>
                  <MKTypography variant="caption" color="text">
                    <a href="/classements">-&gt; Voir le classement</a>
                  </MKTypography>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </MKBox>
        <MKBox component="section" mb={3}>
          <Grid
            container
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            sx={{ mx: "auto", textAlign: "center" }}
            py={5}
          >
            <Grid item xs={12} md={6} px={10}>
              <Card>
                <Grid item m={5}>
                  <MKBox
                    component="img"
                    src={ecommerceMag}
                    alt="image"
                    width="75%"
                    height="75%"
                  />
                  <MKTypography
                    variant="body2"
                    fontWeight="bold"
                    color="text"
                    mt={3}
                  >
                    Ecommerce Mag -
                  </MKTypography>{" "}
                  <MKTypography
                    variant="body2"
                    color="text"
                    textAlign="justify"

                  >
                    <MKTypography variant="caption" color="text">
                      <a
                        href="https://www.ecommercemag.fr/Thematique/retail-1220/Breves/Quelles-ont-ete-les-enseignes-les-mieux-notees-sur-Google-399830.htm"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Lien vers l&apos;article
                      </a>
                    </MKTypography>
                  </MKTypography>
                  <MKTypography variant="caption" color="text">
                    <a href="/classements">-&gt; Voir le classement</a>
                  </MKTypography>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} px={10}>
              <Card>
                <Grid item m={5}>
                  <MKBox
                    component="img"
                    src={officielDeLaFranchise}
                    alt="image"
                    width="75%"
                    height="75%"
                  />
                  <MKTypography
                    variant="body2"
                    fontWeight="bold"
                    color="text"
                    mt={3}
                  >
                    L'officiel de la franchise -
                  </MKTypography>{" "}
                  <MKTypography
                    variant="body2"
                    color="text"
                    textAlign="justify"
                  >
                    <MKTypography variant="caption" color="text">
                      <a
                        href="https://officieldelafranchise.fr/avis-google-quelles-sont-les-enseignes-en-franchise-les-mieux-notees/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Lien vers l&apos;article
                      </a>
                    </MKTypography>
                  </MKTypography>
                  <MKTypography variant="caption" color="text">
                    <a href="/classements">-&gt; Voir le classement</a>
                  </MKTypography>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </MKBox>
        <MKBox component="section" mb={3}>
          <Grid
            container
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            sx={{ mx: "auto", textAlign: "center" }}
            py={5}
          >
            <Grid item xs={12} md={6} px={10}>
              <Card>
                <Grid item m={5}>
                  <MKBox
                    component="img"
                    src={jafInfo}
                    alt="image"
                    width="75%"
                    height="75%"
                  />
                  <MKTypography
                    variant="body2"
                    fontWeight="bold"
                    color="text"
                    mt={3}
                  >
                    Jaf Info -
                  </MKTypography>{" "}
                  <MKTypography
                    variant="body2"
                    color="text"
                    textAlign="justify"

                  >
                    <MKTypography variant="caption" color="text">
                      <a
                        href="https://www.jardinerie-animalerie-fleuriste.fr/actualites/etude-vasano-lenseigne-la-mieux-notee-sur-google-truffaut-et-medor-compagnie-laureats-jardineries-et-animaleries/831230/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Lien vers l&apos;article
                      </a>
                    </MKTypography>
                  </MKTypography>
                  <MKTypography variant="caption" color="text">
                    <a href="/classements">-&gt; Voir le classement</a>
                  </MKTypography>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} px={10}>
              <Card>
                <Grid item m={5}>
                  <MKBox
                    component="img"
                    src={paSport}
                    alt="image"
                    width="75%"
                    height="75%"
                  />
                  <MKTypography
                    variant="body2"
                    fontWeight="bold"
                    color="text"
                    mt={3}
                  >
                    PA Sport -
                  </MKTypography>{" "}
                  <MKTypography
                    variant="body2"
                    color="text"
                    textAlign="justify"
                  >
                    <MKTypography variant="caption" color="text">
                      <a
                        href="https://www.pa-sport.fr/2024/01/18/omnisports-palmares-de-lenseigne-de-sport-la-mieux-notee-sur-google-en-2023/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Lien vers l&apos;article
                      </a>
                    </MKTypography>
                  </MKTypography>
                  <MKTypography variant="caption" color="text">
                    <a href="/classements">-&gt; Voir le classement</a>
                  </MKTypography>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </MKBox>
        <MKBox component="section" mb={3}>
          <Grid
            container
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            sx={{ mx: "auto", textAlign: "center" }}
            py={5}
          >
            <Grid item xs={12} md={6} px={10}>
              <Card>
                <Grid item m={5}>
                  <MKBox
                    component="img"
                    src={louie}
                    alt="image"
                    width="75%"
                    height="75%"
                  />
                  <MKTypography
                    variant="body2"
                    fontWeight="bold"
                    color="text"
                    mt={3}
                  >
                    Jaf Info -
                  </MKTypography>{" "}
                  <MKTypography
                    variant="body2"
                    color="text"
                    textAlign="justify"

                  >
                    <MKTypography variant="caption" color="text">
                      <a
                        href="https://www.jardinerie-animalerie-fleuriste.fr/actualites/etude-vasano-lenseigne-la-mieux-notee-sur-google-truffaut-et-medor-compagnie-laureats-jardineries-et-animaleries/831230/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Lien vers l&apos;article
                      </a>
                    </MKTypography>
                  </MKTypography>
                  <MKTypography variant="caption" color="text">
                    <a href="/classements">-&gt; Voir le classement</a>
                  </MKTypography>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} px={10}>
              <Card>
                <Grid item m={5}>
                  <MKBox
                    component="img"
                    src={paSport}
                    alt="image"
                    width="75%"
                    height="75%"
                  />
                  <MKTypography
                    variant="body2"
                    fontWeight="bold"
                    color="text"
                    mt={3}
                  >
                    PA Sport -
                  </MKTypography>{" "}
                  <MKTypography
                    variant="body2"
                    color="text"
                    textAlign="justify"
                  >
                    <MKTypography variant="caption" color="text">
                      <a
                        href="https://www.pa-sport.fr/2024/01/18/omnisports-palmares-de-lenseigne-de-sport-la-mieux-notee-sur-google-en-2023/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Lien vers l&apos;article
                      </a>
                    </MKTypography>
                  </MKTypography>
                  <MKTypography variant="caption" color="text">
                    <a href="/classements">-&gt; Voir le classement</a>
                  </MKTypography>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </MKBox>
        <MKBox component="section" mb={3}>
          <Grid
            container
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            sx={{ mx: "auto", textAlign: "center" }}
            py={5}
          >
            <Grid item xs={12} md={6} px={10}>
              <Card>
                <Grid item m={5}>
                  <MKBox
                    component="img"
                    src={louie}
                    alt="image"
                    width="75%"
                    height="75%"
                  />
                  <MKTypography
                    variant="body2"
                    fontWeight="bold"
                    color="text"
                    mt={3}
                  >
                    L'Ouïe -
                  </MKTypography>{" "}
                  <MKTypography
                    variant="body2"
                    color="text"
                    textAlign="justify"

                  >
                    <MKTypography variant="caption" color="text">
                      <a
                        href="https://www.ouiemagazine.net/2024/01/18/avis-google-3-enseignes-daudition-en-tete/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Lien vers l&apos;article
                      </a>
                    </MKTypography>
                  </MKTypography>
                  <MKTypography variant="caption" color="text">
                    <a href="/classements">-&gt; Voir le classement</a>
                  </MKTypography>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} px={10}>
              <Card>
                <Grid item m={5}>
                  <MKBox
                    component="img"
                    src={femina}
                    alt="image"
                    width="75%"
                    height="75%"
                  />
                  <MKTypography
                    variant="body2"
                    fontWeight="bold"
                    color="text"
                    mt={3}
                  >
                    Femina -
                  </MKTypography>{" "}
                  <MKTypography
                    variant="body2"
                    color="text"
                    textAlign="justify"
                  >
                    <MKTypography variant="caption" color="text">
                      <a
                        href="https://www.femina.fr/article/meilleur-institut-de-beaute-en-france"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Lien vers l&apos;article
                      </a>
                    </MKTypography>
                  </MKTypography>
                  <MKTypography variant="caption" color="text">
                    <a href="/classements">-&gt; Voir le classement</a>
                  </MKTypography>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </MKBox>
        <MKBox component="section" mb={3}>
          <Grid
            container
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            sx={{ mx: "auto", textAlign: "center" }}
            py={5}
          >
            <Grid item xs={12} md={6} px={10}>
              <Card>
                <Grid item m={5}>
                  <MKBox
                    component="img"
                    src={cosmopolitan}
                    alt="image"
                    width="75%"
                    height="75%"
                  />
                  <MKTypography
                    variant="body2"
                    fontWeight="bold"
                    color="text"
                    mt={3}
                  >
                    Cosmopolitan -
                  </MKTypography>{" "}
                  <MKTypography
                    variant="body2"
                    color="text"
                    textAlign="justify"

                  >
                    <MKTypography variant="caption" color="text">
                      <a
                        href="https://www.cosmopolitan.fr/meilleur-institut-de-beaute-france-2023,2109478.asp"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Lien vers l&apos;article
                      </a>
                    </MKTypography>
                  </MKTypography>
                  <MKTypography variant="caption" color="text">
                    <a href="/classements">-&gt; Voir le classement</a>
                  </MKTypography>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} px={10}>
              <Card>
                <Grid item m={5}>
                  <MKBox
                    component="img"
                    src={marieFrance}
                    alt="image"
                    width="75%"
                    height="75%"
                  />
                  <MKTypography
                    variant="body2"
                    fontWeight="bold"
                    color="text"
                    mt={3}
                  >
                    Marie France -
                  </MKTypography>{" "}
                  <MKTypography
                    variant="body2"
                    color="text"
                    textAlign="justify"
                  >
                    <MKTypography variant="caption" color="text">
                      <a
                        href="https://www.mariefrance.fr/beaute/meilleure-chaine-salons-coiffure-2024-selon-avis-clients-917344.html"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Lien vers l&apos;article
                      </a>
                    </MKTypography>
                  </MKTypography>
                  <MKTypography variant="caption" color="text">
                    <a href="/classements">-&gt; Voir le classement</a>
                  </MKTypography>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </MKBox>
        <MKBox component="section" mb={3}>
          <Grid
            container
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            sx={{ mx: "auto", textAlign: "center" }}
            py={5}
          >
            <Grid item xs={12} md={6} px={10}>
              <Card>
                <Grid item m={5}>
                  <MKBox
                    component="img"
                    src={zepros2}
                    alt="image"
                    width="75%"
                    height="75%"
                  />
                  <MKTypography
                    variant="body2"
                    fontWeight="bold"
                    color="text"
                    mt={3}
                  >
                    ZEPROS -
                  </MKTypography>{" "}
                  <MKTypography
                    variant="body2"
                    color="text"
                    textAlign="justify"

                  >
                    <MKTypography variant="caption" color="text">
                      <a
                        href="https://habitat.zepros.fr/actus/truffaut-gedimat-weldom-cartonnent-google-2023"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Lien vers l&apos;article
                      </a>
                    </MKTypography>
                  </MKTypography>
                  <MKTypography variant="caption" color="text">
                    <a href="/classements">-&gt; Voir le classement</a>
                  </MKTypography>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} px={10}>
              <Card>
                <Grid item m={5}>
                  <MKBox
                    component="img"
                    src={meubleInfos}
                    alt="image"
                    width="75%"
                    height="75%"
                  />
                  <MKTypography
                    variant="body2"
                    fontWeight="bold"
                    color="text"
                    mt={3}
                  >
                    Meuble infos -
                  </MKTypography>{" "}
                  <MKTypography
                    variant="body2"
                    color="text"
                    textAlign="justify"
                  >
                    <MKTypography variant="caption" color="text">
                      <a
                        href="https://meuble-info.fr/mobilier-de-france-enseigne-dameublement-la-mieux-notee-sur-google-en-2023/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Lien vers l&apos;article
                      </a>
                    </MKTypography>
                  </MKTypography>
                  <MKTypography variant="caption" color="text">
                    <a href="/classements">-&gt; Voir le classement</a>
                  </MKTypography>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </MKBox>
        <MKBox component="section" mb={3}>
          <Grid
            container
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            sx={{ mx: "auto", textAlign: "center" }}
            py={5}
          >
            <Grid item xs={12} md={6} px={10}>
              <Card>
                <Grid item m={5}>
                  <MKBox
                    component="img"
                    src={universHabitat2}
                    alt="image"
                    width="75%"
                    height="75%"
                  />
                  <MKTypography
                    variant="body2"
                    fontWeight="bold"
                    color="text"
                    mt={3}
                  >
                    Univers Habitat -
                  </MKTypography>{" "}
                  <MKTypography
                    variant="body2"
                    color="text"
                    textAlign="justify"

                  >
                    <MKTypography variant="caption" color="text">
                      <a
                        href="https://www.univers-habitat.eu/mobilier/2024/01/24/mobilier-de-france-remporte-le-prix-de-lenseigne-dameublement-la-mieux-notee-sur-google-en-2023/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Lien vers l&apos;article
                      </a>
                    </MKTypography>
                  </MKTypography>
                  <MKTypography variant="caption" color="text">
                    <a href="/classements">-&gt; Voir le classement</a>
                  </MKTypography>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} px={10}>
              <Card>
                <Grid item m={5}>
                  <MKBox
                    component="img"
                    src={batipresse}
                    alt="image"
                    width="75%"
                    height="75%"
                  />
                  <MKTypography
                    variant="body2"
                    fontWeight="bold"
                    color="text"
                    mt={3}
                  >
                    Batipresse -
                  </MKTypography>{" "}
                  <MKTypography
                    variant="body2"
                    color="text"
                    textAlign="justify"
                  >
                    <MKTypography variant="caption" color="text">
                      <a
                        href="https://www.batipresse.com/2024/01/26/gedimat-laureat-du-prix-de-lenseigne-la-mieux-notee-sur-google-en-2023/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Lien vers l&apos;article
                      </a>
                    </MKTypography>
                  </MKTypography>
                  <MKTypography variant="caption" color="text">
                    <a href="/classements">-&gt; Voir le classement</a>
                  </MKTypography>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </MKBox>
        <MKBox component="section" mb={3}>
          <Grid
            container
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            sx={{ mx: "auto", textAlign: "center" }}
            py={5}
          >
            <Grid item xs={12} md={6} px={10}>
              <Card>
                <Grid item m={5}>
                  <MKBox
                    component="img"
                    src={batipole}
                    alt="image"
                    width="75%"
                    height="75%"
                  />
                  <MKTypography
                    variant="body2"
                    fontWeight="bold"
                    color="text"
                    mt={3}
                  >
                    Batipole -
                  </MKTypography>{" "}
                  <MKTypography
                    variant="body2"
                    color="text"
                    textAlign="justify"

                  >
                    <MKTypography variant="caption" color="text">
                      <a
                        href="https://www.batipole.com/actualites/gedimat-laureat-du-prix-de-lenseigne-la-mieux-notee-sur-google-en-2023"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Lien vers l&apos;article
                      </a>
                    </MKTypography>
                  </MKTypography>
                  <MKTypography variant="caption" color="text">
                    <a href="/classements">-&gt; Voir le classement</a>
                  </MKTypography>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} px={10}>
              <Card>
                <Grid item m={5}>
                  <MKBox
                    component="img"
                    src={choisirSaFranchise}
                    alt="image"
                    width="75%"
                    height="75%"
                  />
                  <MKTypography
                    variant="body2"
                    fontWeight="bold"
                    color="text"
                    mt={3}
                  >
                    Choisir sa franchise -
                  </MKTypography>{" "}
                  <MKTypography
                    variant="body2"
                    color="text"
                    textAlign="justify"
                  >
                    <MKTypography variant="caption" color="text">
                      <a
                        href="https://www.choisir-sa-franchise.com/actualites-franchise/2024-01-22-cuisines-references-laureat-prix-enseigne-mieux-notee-google-2023-categorie-cuisinistes"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Lien vers l&apos;article
                      </a>
                    </MKTypography>
                  </MKTypography>
                  <MKTypography variant="caption" color="text">
                    <a href="/classements">-&gt; Voir le classement</a>
                  </MKTypography>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </MKBox>
        <MKBox component="section" mb={3}>
          <Grid
            container
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            sx={{ mx: "auto", textAlign: "center" }}
            py={5}
          >
            <Grid item xs={12} md={6} px={10}>
              <Card>
                <Grid item m={5}>
                  <MKBox
                    component="img"
                    src={leMoniteur}
                    alt="image"
                    width="75%"
                    height="75%"
                  />
                  <MKTypography
                    variant="body2"
                    fontWeight="bold"
                    color="text"
                    mt={3}
                  >
                    Le Moniteur de Seine-et-Marne -
                  </MKTypography>{" "}
                  <MKTypography
                    variant="body2"
                    color="text"
                    textAlign="justify"
                  >
                    L’Agence bancaire LCL D’Ozoir-la-Ferrière plébiscitée par ses clients{" "}
                    <MKTypography variant="caption" color="text">
                      <a
                        href="https://mesinfos.fr/ile-de-france/l-agence-bancaire-lcl-d-ozoir-la-ferriere-plebiscitee-par-ses-clients-185631.html"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Lien vers l&apos;article
                      </a>
                    </MKTypography>
                  </MKTypography>
                  <MKTypography variant="caption" color="text">
                    <a href="/classements">-&gt; Voir le classement</a>
                  </MKTypography>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} px={10}>
              <Card>
                <Grid item m={5}>
                  <MKBox
                    component="img"
                    src={j2r2}
                    alt="image"
                    width="75%"
                    height="75%"
                  />
                  <MKTypography
                    variant="body2"
                    fontWeight="bold"
                    color="text"
                    mt={3}
                  >
                    J2R Le journal de la rechange ET DE LA REPARATION -
                  </MKTypography>{" "}
                  <MKTypography
                    variant="body2"
                    color="text"
                    textAlign="justify"
                  >
                    Annonce le lancement d’une nouvelle rubrique dans le J2R : le baromètre VASANO Solutions. Qui présentera les données et des indicateurs exclusifs sur la satisfaction client et l’e-reputation des réseaux de maintenance automobile.
                    Découvrez le score moyen de chaque enseigne dans votre J2R !
                    {" "}
                    <MKTypography variant="caption" color="text">
                      <a
                        href="https://j2rauto.com/reseaux/le-garage-automobile-et-le-reparateur-de-bris-de-glace-les-mieux-notes-sur-google-sont/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Lien vers l&apos;article
                      </a>
                    </MKTypography>
                  </MKTypography>
                  <MKTypography variant="caption" color="text">
                    <a href="/classements">-&gt; Voir le classement</a>
                  </MKTypography>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </MKBox>
        <MKBox component="section" mb={3}>
          <Grid
            container
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            sx={{ mx: "auto", textAlign: "center" }}
            py={5}
          >
            <Grid item xs={12} md={6} px={10}>
              <Card>
                <Grid item m={5}>
                  <MKBox
                    component="img"
                    src={referencePareBrise}
                    alt="image"
                    width="75%"
                    height="75%"
                  />
                  <MKTypography
                    variant="body2"
                    fontWeight="bold"
                    color="text"
                    mt={3}
                  >
                    REFERENCE PARE-BRISE -
                  </MKTypography>{" "}
                  <MKTypography
                    variant="body2"
                    color="text"
                    textAlign="justify"
                  >
                    REFERENCE PARE-BRISE obtient le label VASANO !{" "}
                    <MKTypography variant="caption" color="text">
                      <a
                        href="https://j2rauto.com/reseaux/le-garage-automobile-et-le-reparateur-de-bris-de-glace-les-mieux-notes-sur-google-sont/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Lien vers l&apos;article
                      </a>
                    </MKTypography>
                  </MKTypography>
                  <MKTypography variant="caption" color="text">
                    <a href="/classements">-&gt; Voir le classement</a>
                  </MKTypography>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} px={10}>
              <Card>
                <Grid item m={5}>
                  <MKBox
                    component="img"
                    src={digitalmag}
                    alt="image"
                    width="75%"
                    height="75%"
                  />
                  <MKTypography
                    variant="body2"
                    fontWeight="bold"
                    color="text"
                    mt={3}
                  >
                    DIGITALMAG -
                  </MKTypography>{" "}
                  <MKTypography
                    variant="body2"
                    color="text"
                    textAlign="justify"
                  >
                    DIGITALMAG pour le salon RETAIL WEEK 2023, a publié une étude VASANO{" "}
                    <MKTypography variant="caption" color="text">
                      <a
                        href="https://www.digital-mag.fr/magazines/digitalmag-274/#quietlyLook"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Lien vers l&apos;article
                      </a>
                    </MKTypography>
                  </MKTypography>
                  <MKTypography variant="caption" color="text">
                    <a href="/classements">-&gt; Voir le classement</a>
                  </MKTypography>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </MKBox>
        <MKBox component="section" mb={3}>
          <Grid
            container
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            sx={{ mx: "auto", textAlign: "center" }}
            py={5}
          >
            <Grid item xs={12} md={6} px={10}>
              <Card>
                <Grid item m={5}>
                  <MKBox
                    component="img"
                    src={jdn}
                    alt="image"
                    width="75%"
                    height="75%"
                  />
                  <MKTypography
                    variant="body2"
                    fontWeight="bold"
                    color="text"
                    mt={3}
                  >
                    Journal Du Net (JDN) -
                  </MKTypography>{" "}
                  <MKTypography
                    variant="body2"
                    color="text"
                    textAlign="justify"
                  >
                    JDN en parle{" "}
                    <MKTypography variant="caption" color="text">
                      <a
                        href="https://www.journaldunet.com/ebusiness/crm-marketing/1524849-les-avis-google-plus-qu-un-outil-de-fidelisation-un-veritable-moteur-de-performance/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Lien vers l&apos;article
                      </a>
                    </MKTypography>
                  </MKTypography>
                  <MKTypography variant="caption" color="text">
                    <a href="/classements">-&gt; Voir le classement</a>
                  </MKTypography>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} px={10}>
              <Card>
                <Grid item m={5}>
                  <MKBox
                    component="img"
                    src={topCom}
                    alt="image"
                    width="75%"
                    height="75%"
                  />
                  <MKTypography
                    variant="body2"
                    fontWeight="bold"
                    color="text"
                    mt={3}
                  >
                    TOP COM -
                  </MKTypography>{" "}
                  <MKTypography
                    variant="body2"
                    color="text"
                    textAlign="justify"
                  >
                    TOP COM en parle{" "}
                    <MKTypography variant="caption" color="text">
                      <a
                        href="http://topcom.fr/breve/etude-vasano-quelles-sont-les-10-enseignes-generant-le-plus-davis-google-en-france/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Lien vers l&apos;article
                      </a>
                    </MKTypography>
                  </MKTypography>
                  <MKTypography variant="caption" color="text">
                    <a href="/classements">-&gt; Voir le classement</a>
                  </MKTypography>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </MKBox>
        <MKBox component="section" mb={3}>
          <Grid
              container
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              sx={{ mx: "auto", textAlign: "center" }}
              py={5}
            >
            <Grid item xs={12} md={6} px={10}>
              <Card>
                <Grid item m={5}>
                  <MKBox
                    component="img"
                    src={nextStage}
                    alt="image"
                    width="75%"
                    height="75%"
                  />
                  <MKTypography
                    variant="body2"
                    fontWeight="bold"
                    color="text"
                    mt={3}
                  >
                    NextStage -
                  </MKTypography>{" "}
                  <MKTypography
                    variant="body2"
                    color="text"
                    textAlign="justify"
                  >
                    sur nos podiums de la parfumerie et les cosmetiques. “Adopt
                    Parfums s’impose et prend la première place du classement
                    des enseignes les mieux notées en France dans la catégorie
                    Parfumerie et Cosmétique par Vasano Solutions...”{" "}
                    <MKTypography variant="caption" color="text">
                      <a
                        href="https://nextstage-am.com/adopt%E2%80%8B-parfums-prend-la-premiere-place-du-classement-de-vasano-solutions/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Lien vers l&apos;article
                      </a>
                    </MKTypography>
                  </MKTypography>
                  <MKTypography variant="caption" color="text">
                    <a href="/podiums">-&gt; Voir le podium VASANO SOLUTIONS</a>
                  </MKTypography>
                </Grid>
              </Card>
            </Grid>
            <Grid xs={12} md={6} px={10}>
              <Card>
                <Grid item m={5}>
                  <MKBox
                    component="img"
                    src={decisionAtelier}
                    alt="image"
                    width="75%"
                    height="75%"
                  />
                  <MKTypography
                    variant="body2"
                    fontWeight="bold"
                    color="text"
                    mt={3}
                  >
                    Décision Atelier -
                  </MKTypography>{" "}
                  <MKTypography
                    variant="body2"
                    color="text"
                    textAlign="justify"
                  >
                    sur nos podiums de l’après-vente automobile. “La petite
                    entreprise scrute plus de 30 catégories d’activités à
                    travers plus de 500 réseaux et chaînes, soit plus de 1 300
                    000 établissements. Vasano Solutions se défend d’être, à
                    tort, comparé à une activité de sondage...”{" "}
                    <MKTypography variant="caption" color="text">
                      <a
                        href="https://www.auto-infos.fr/article/vasano-solutions-scrute-le-jugement-des-francais-sur-les-enseignes-d-apv-automobiles.260866"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Lien vers l&apos;article
                      </a>
                    </MKTypography>
                  </MKTypography>
                  <MKTypography variant="caption" color="text">
                    <a href="/podiums">-&gt; Voir le podium VASANO SOLUTIONS</a>
                  </MKTypography>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </MKBox>
        <MKBox component="section" mb={3}>
          <Grid
            container
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            sx={{ mx: "auto", textAlign: "center" }}
            py={5}
          >
            <Grid item xs={12} md={6} px={10}>
              <Card>
                <Grid item m={5}>
                  <MKBox
                    component="img"
                    src={human}
                    alt="image"
                    width="75%"
                    height="75%"
                  />
                  <MKTypography
                    variant="body2"
                    fontWeight="bold"
                    color="text"
                    mt={3}
                  >
                    Human Immobilier -
                  </MKTypography>{" "}
                  <MKTypography
                    variant="body2"
                    color="text"
                    textAlign="justify"
                  >
                    sur nos podiums de l’immobilier. “Le 20 octobre 2022, HUMAN
                    Immobilier a été désigné comme le réseau d&apos;agences
                    immobilières préféré des Français selon une étude réalisée
                    par VASANO Solutions, un institut totalement indépendant...”{" "}
                    <MKTypography variant="caption" color="text">
                      <a
                        href="https://www.human-immobilier.fr/human/human-news/human-immobilier-est-l-enseigne-preferee-des-francais/1395"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Lien vers l&apos;article
                      </a>
                    </MKTypography>
                  </MKTypography>
                  <MKTypography variant="caption" color="text">
                    <a href="/podiums">-&gt; Voir le podium VASANO SOLUTIONS</a>
                  </MKTypography>
                </Grid>
              </Card>
            </Grid>
            <Grid xs={12} md={6} px={10}>
              <Card>
                <Grid item m={5}>
                  <MKBox
                    component="img"
                    src={lcl}
                    alt="image"
                    width="75%"
                    height="75%"
                  />
                  <MKTypography
                    variant="body2"
                    fontWeight="bold"
                    color="text"
                    mt={3}
                  >
                    LCL -
                  </MKTypography>{" "}
                  <MKTypography
                    variant="body2"
                    color="text"
                    textAlign="justify"
                  >
                    sur nos podiums du secteur bancaire. “Selon une étude
                    réalisée par le cabinet Vasano Solutions, LCL est
                    l&apos;enseigne la mieux notée sur Google en 2022 pour...”{" "}
                    <MKTypography variant="caption" color="text">
                      <a
                        href="https://www.lcl.fr/decouvrir-lcl/lcl-banque-mieux-note-google-2022"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Lien vers l&apos;article
                      </a>
                    </MKTypography>
                  </MKTypography>
                  <MKTypography variant="caption" color="text">
                    <a href="/podiums">-&gt; Voir le podium VASANO SOLUTIONS</a>
                  </MKTypography>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </MKBox>
        <MKBox component="section" mb={3}>
          <Grid
            container
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            sx={{ mx: "auto", textAlign: "center" }}
            py={5}
          >
            <Grid item xs={12} md={6} px={10}>
              <Card>
                <Grid item m={5}>
                  <MKBox
                    component="img"
                    src={zepros}
                    alt="image"
                    width="75%"
                    height="75%"
                  />
                  <MKTypography
                    variant="body2"
                    fontWeight="bold"
                    color="text"
                    mt={3}
                  >
                    ZEPROS -
                  </MKTypography>{" "}
                  <MKTypography
                    variant="body2"
                    color="text"
                    textAlign="justify"
                  >
                    sur nos podiums du secteur pare-brise. “D’après une étude de
                    Vasano Solutions, les enseignes de bris de glace les mieux
                    notées sur Google fonctionnent toutes sans agrément...”{" "}
                    <MKTypography variant="caption" color="text">
                      <a
                        href="https://auto.zepros.fr/carrossiers/vitrage/reseaux-vitrage-non-agrees-mieux-notes"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Lien vers l&apos;article
                      </a>
                    </MKTypography>
                  </MKTypography>
                  <MKTypography variant="caption" color="text">
                    <a href="/podiums">-&gt; Voir le podium VASANO SOLUTIONS</a>
                  </MKTypography>
                </Grid>
              </Card>
            </Grid>
            <Grid xs={12} md={6} px={10}>
              <Card>
                <Grid item m={5}>
                  <MKBox
                    component="img"
                    src={j2r}
                    alt="image"
                    width="75%"
                    height="75%"
                  />
                  <MKTypography
                    variant="body2"
                    fontWeight="bold"
                    color="text"
                    mt={3}
                  >
                    J2R JOURNAL -
                  </MKTypography>{" "}
                  <MKTypography
                    variant="body2"
                    color="text"
                    textAlign="justify"
                  >
                    sur nos podiums de l’entretien automobile. “La société
                    Vasano Solutions a analysé les avis Google reçus par de
                    nombreuses enseignes d’entretien automobile, de contrôle
                    technique ou encore de spécialistes du pare-brise. Découvrez
                    le....”{" "}
                    <MKTypography variant="caption" color="text">
                      <a
                        href="https://j2rauto.com/reseaux/en-2022-les-enseignes-automobiles-les-mieux-notees-sur-google-sont/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Lien vers l&apos;article
                      </a>
                    </MKTypography>
                  </MKTypography>
                  <MKTypography variant="caption" color="text">
                    <a href="/podiums">-&gt; Voir le podium VASANO SOLUTIONS</a>
                  </MKTypography>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </MKBox>
        <MKBox component="section" mb={3}>
          <Grid
            container
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            sx={{ mx: "auto", textAlign: "center" }}
            py={5}
          >
            <Grid item xs={12} md={6} px={10}>
              <Card>
                <Grid item m={5}>
                  <MKBox
                    component="img"
                    src={kw}
                    alt="image"
                    width="75%"
                    height="75%"
                  />
                  <MKTypography
                    variant="body2"
                    fontWeight="bold"
                    color="text"
                    mt={3}
                  >
                    Keller Wiliams -
                  </MKTypography>{" "}
                  <MKTypography
                    variant="body2"
                    color="text"
                    textAlign="justify"
                  >
                    sur nos podiums de l’immobilier. “Keller Williams France sur
                    le podium des enseignes immobilières préférées des français
                    en 2022...”{" "}
                    <MKTypography variant="caption" color="text">
                      <a
                        href="https://carrieres.kwfrance.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Lien vers l&apos;article
                      </a>
                    </MKTypography>
                  </MKTypography>
                  <MKTypography variant="caption" color="text">
                    <a href="/podiums">-&gt; Voir le podium VASANO SOLUTIONS</a>
                  </MKTypography>
                </Grid>
              </Card>
            </Grid>
            <Grid xs={12} md={6} px={10}>
              <Card>
                <Grid item m={5}>
                  <MKBox
                    component="img"
                    src={telegramme}
                    alt="image"
                    width="75%"
                    height="75%"
                  />
                  <MKTypography
                    variant="body2"
                    fontWeight="bold"
                    color="text"
                    mt={3}
                  >
                    Le Télégramme -
                  </MKTypography>{" "}
                  <MKTypography
                    variant="body2"
                    color="text"
                    textAlign="justify"
                  >
                    sur nos podiums des GMS. “Quelle est l’enseigne de
                    supermarché la mieux notée par Google en Bretagne ?
                    [Exclusif]...”{" "}
                    <MKTypography variant="caption" color="text">
                      <a
                        href="https://www.letelegramme.fr/economie/quelle-est-l-enseigne-de-supermarche-la-mieux-notee-par-google-en-bretagne-exclusif-03-02-2023-13271985.php"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Lien vers l&apos;article
                      </a>
                    </MKTypography>
                  </MKTypography>
                  <MKTypography variant="caption" color="text">
                    <a href="/podiums">-&gt; Voir le podium VASANO SOLUTIONS</a>
                  </MKTypography>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </MKBox>
        <MKBox component="section" mb={3}>
          <Grid
            container
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            sx={{ mx: "auto", textAlign: "center" }}
            py={5}
          >
            <Grid item xs={12} md={6} px={10}>
              <Card>
                <Grid item m={5}>
                  <MKBox
                    component="img"
                    src={altavia}
                    alt="image"
                    width="75%"
                    height="75%"
                  />
                  <MKTypography
                    variant="body2"
                    fontWeight="bold"
                    color="text"
                    mt={3}
                  >
                    Altavia.Watch -
                  </MKTypography>{" "}
                  <MKTypography
                    variant="body2"
                    color="text"
                    textAlign="justify"
                  >
                    sur nos podiums des GMS. “Si l’on en croit les notes Google
                    données par les clients en 2022, c’est Lidl qui, en France,
                    a su le mieux répondre...”{" "}
                    <MKTypography variant="caption" color="text">
                      <a
                        href="https://altaviawatch.com/actualites/info-retailers/lidl-enseigne-la-mieux-notee-par-ses-clients-sur-google-en-2022/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Lien vers l&apos;article
                      </a>
                    </MKTypography>
                  </MKTypography>
                  <MKTypography variant="caption" color="text">
                    <a href="/podiums">-&gt; Voir le podium VASANO SOLUTIONS</a>
                  </MKTypography>
                </Grid>
              </Card>
            </Grid>
            <Grid xs={12} md={6} px={10}>
              <Card>
                <Grid item m={5}>
                  <MKBox
                    component="img"
                    src={universHabitat}
                    alt="image"
                    width="75%"
                    height="75%"
                  />
                  <MKTypography
                    variant="body2"
                    fontWeight="bold"
                    color="text"
                    mt={3}
                  >
                    Univers Habitat -
                  </MKTypography>{" "}
                  <MKTypography
                    variant="body2"
                    color="text"
                    textAlign="justify"
                  >
                    sur nos podiums des cuisines. “Depuis sa création en 1989,
                    l’enseigne du groupe FBD n’a cessé d’évoluer. Preuve en est,
                    elle annonce aujourd’hui avoir doublé son chiffre d’affaires
                    en...”{" "}
                    <MKTypography variant="caption" color="text">
                      <a
                        href="https://www.univers-habitat.eu/cuisine/2023/03/02/cuisines-references-devoile-un-bilan-2022-positif-et-se-place-en-1ere-position-des-avis-google_10759/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Lien vers l&apos;article
                      </a>
                    </MKTypography>
                  </MKTypography>
                  <MKTypography variant="caption" color="text">
                    <a href="/podiums">-&gt; Voir le podium VASANO SOLUTIONS</a>
                  </MKTypography>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </MKBox>
        <MKBox component="section" mb={3}>
          <Grid
            container
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            sx={{ mx: "auto", textAlign: "center" }}
            py={5}
          >
            <Grid item xs={12} md={6} px={10}>
              <Card>
                <Grid item m={5}>
                  <MKBox
                    component="img"
                    src={pleineVie}
                    alt="image"
                    width="50%"
                    height="50%"
                  />
                  <MKTypography
                    variant="body2"
                    fontWeight="bold"
                    color="text"
                    mt={3}
                  >
                    Pleine vie -
                  </MKTypography>{" "}
                  <MKTypography variant="body2" color="text" textAlign="center">
                    sur nos podiums des cuisines. “Nous sommes fiers d’être
                    l’enseigne de la grande distribution la mieux notée en 2022
                    sur #Google selon le classement Vas...”{" "}
                    <MKTypography variant="caption" color="text">
                      <a
                        href="https://www.pleinevie.fr/conso-argent/consommation/nouveaute-lidl-a-prix-casse-cet-appareil-cuisine-vendu-a-moins-de-30e-vous-permettra-de-manger-plus-sainement-80241.html"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Lien vers l&apos;article
                      </a>
                    </MKTypography>
                  </MKTypography>
                  <MKTypography variant="caption" color="text">
                    <a href="/podiums">-&gt; Voir le podium VASANO SOLUTIONS</a>
                  </MKTypography>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} px={10}>
              <Card>
                <Grid item m={5}>
                  <MKBox
                    component="img"
                    src={itr}
                    alt="image"
                    width="75%"
                    height="75%"
                  />
                  <MKTypography
                    variant="body2"
                    fontWeight="bold"
                    color="text"
                    mt={3}
                  >
                    ITRpress -
                  </MKTypography>{" "}
                  <MKTypography
                    variant="body2"
                    color="text"
                    textAlign="justify"
                  >
                    VASANO est dans l’info du Groupe ITRpress{" "}
                    <MKTypography variant="caption" color="text">
                      <a
                        href="https://itrinnovation.com/articles/199323/voici-les-10-enseignes-en-france-qui-suscitent-le-plus-dengagement-sur-google.html"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Lien vers l&apos;article
                      </a>
                    </MKTypography>
                  </MKTypography>
                  <MKTypography variant="caption" color="text">
                    <a href="/classement">-&gt; Voir le classement</a>
                  </MKTypography>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </MKBox>
        <Divider />
        <MKBox component="section" mb={3}>
          <MKTypography variant="h1" textAlign="center" mt={5}>
            Sur les réseaux
          </MKTypography>
          <Grid
            container
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            sx={{ mx: "auto", textAlign: "center" }}
            py={10}
          >
            <Grid item xs={6}>
              <MKBox
                component="img"
                src={linkedIn}
                alt="image"
                width="50%"
                height="50%"
              />
            </Grid>
            <Grid item xs={6}>
              <MKBox
                component="img"
                src={twitter}
                alt="image"
                width="65%"
                height="65%"
              />
            </Grid>
          </Grid>
        </MKBox>
      </Card>
      <MKBox>
        <Footer content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Presse;
