/*
=========================================================
* Otis Kit PRO - v2.0.0
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// import Header from "examples/Navbars/CustomNavbar/Header";
import Footer from "examples/Footers/CenteredFooter";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import Link from "@mui/material/Link";

import footerRoutes from "footer.routes";

import routes from "routes";

// Images
import bgImage from "assets/images/bg-blog.jpg";
import Sector from "./sections/sectors";

function Blog() {
  return (
    <>
      <MKBox variant="gradient" bgColor="dark" shadow="sm">
        <DefaultNavbar routes={routes} transparent relative light center />
      </MKBox>
      <MKBox
        minHeight="100vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
          minHeight: 700,
        }}
      >
        <Container>
          <MKTypography
            variant="h1"
            color="white"
            textAlign="center"
            sx={({ breakpoints, typography: { size } }) => ({
              [breakpoints.down("xl")]: {
                fontSize: size["3xl"],
              },
            })}
          >
            Les enseignes les mieux notées sur Google en 2023
          </MKTypography>
          <MKTypography
            variant="body2"
            color="white"
            textAlign="center"
            sx={({ breakpoints, typography: { size } }) => ({
              [breakpoints.down("xl")]: {
                fontSize: size["3xl"],
              },
            })}
          >
            Classement établi en janvier 2024
          </MKTypography>
          <Grid container mt={1} mb={5} justifyContent="center" spacing={5}>
            <Grid item xs={12}>
              <MKTypography
                variant="body1"
                color="white"
                textAlign="justify"
                px={2}
                mb={3}
              >
                <br />
                En 2023, VASANO SOLUTIONS a compilé plus de 50 millions
                d&apos;avis publiés par Google, dont 10 millions sur les
                magasins affiliés à des enseignes en réseaux.
                <br />
                Découvrez les lauréats 2023 dans 21 activités différentes.
                <br />
                Important : Seules les notes 2023 sont prises en compte et nous avons choisi de privilégier la
                récence des évaluations en ne retenant que celles de l’année
                écoulée.
                <br />
                Nota : Voir les conditions{" "}
                <Link
                  href="/methodologie LES ENSEIGNES LES MIEUX NOTEES SUR GOOGLE EN 2023.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ici
                </Link>
                .
              </MKTypography>
              <MKTypography
                variant="h4"
                color="white"
                textAlign="center"
                px={2}
                mt={3}
              >
                Ci-dessous les Lauréats Google 2023
              </MKTypography>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
            rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Sector />
        <MKBox component="section">
          <Grid
            container
            item
            flexDirection="column"
            alignItems="center"
            mx="auto"
            mb={2}
          >
            <MKTypography
              variant="body1"
              color="text"
              opacity={0.8}
              fontWeight="bold"
              textAlign="left"
            >
              Nota : les établissements retenus dans nos classements doivent
              réunir 5 conditions :
              <MKTypography
                variant="body1"
                color="text"
                fontWeight="bold"
                textAlign="left"
              >
                - Avoir au moins six notes par fiche sur Google.
              </MKTypography>
              <MKTypography
                variant="body1"
                color="text"
                fontWeight="bold"
                textAlign="left"
              >
                - Avoir une adresse identifiable dans leur fiche Google.
              </MKTypography>
              <MKTypography
                variant="body1"
                color="text"
                fontWeight="bold"
                textAlign="left"
              >
                - Avoir une activité déclarée dans leur fiche Google qui
                correspond au secteur de leur réseau {" "}
                <Link
                  href="/methodologie LES ENSEIGNES LES MIEUX NOTEES SUR GOOGLE EN 2023.pdf"
                  target="_blank"
                >
                  (voir la sélection par activité)
                </Link>.
              </MKTypography>
              <MKTypography
                variant="body1"
                color="text"
                fontWeight="bold"
                textAlign="left"
              >
                - Être en activité (absence de mention &quot;définitivement
                fermé&quot; sur la fiche Google)
              </MKTypography>
              <br />
            </MKTypography>
            <Grid container justifyContent="center" spacing={5}>
              <Grid item>
                <MKButton
                  variant="gradient"
                  color="info"
                  href="/methodologie LES ENSEIGNES LES MIEUX NOTEES SUR GOOGLE EN 2023.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  size="large"
                >
                  Methodologie
                </MKButton>
              </Grid>
            </Grid>
          </Grid>
        </MKBox>
      </Card>
      <MKBox>
        <Footer content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Blog;
