/*
=========================================================
* Otis Kit PRO - v2.0.0
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Otis Kit PRO examples
import Footer from "examples/Footers/CenteredFooter";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Routes
import footerRoutes from "footer.routes";
import routes from "routes";

function Privacy() {
  return (
    <>
      <MKBox variant="gradient" bgColor="dark" shadow="sm">
        <DefaultNavbar routes={routes} transparent relative light center />
      </MKBox>
      <MKBox component="section" pt={20} pb={12}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Card>
                <MKBox
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="dark"
                  p={3}
                  mt={-3}
                  mx={2}
                >
                  <MKTypography variant="h3" color="white">
                    Confidentialité
                  </MKTypography>
                  <MKTypography variant="body2" color="white" opacity={0.8}>
                    Mise en application: 10 Avril 2022
                  </MKTypography>
                </MKBox>
                <MKBox pb={6} px={6}>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Introduction
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    Le présent site est édité par la société VASANO SOLUTIONS,
                    dont le siège social est au 10 rue de la Paix 75002 PARIS,
                    Société par Actions Simplifiée au capital de 269 000 euros,
                    RCS Bergerac 800 465 353. Editeur du site : VASANO SOLUTIONS
                    (« L’Editeur »)
                    <br />
                    <br />
                    – Directeur de publication : Jean-Marc CHABROL
                    <br />
                    <br />
                    – Conception et développement : Maximilien DAVID
                    <br />
                    <br />
                    Hébergement du site : OVH – 2 rue Kellermann – 59100 Roubaix
                    Crédits photographiques : droits réservés .
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Traitement de vos données personnelles
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    Via les formulaires disponibles sur le présent site web,
                    VASANO SOLUTIONS est susceptible de collecter vos données
                    personnelles. Elles sont collectées et traitées dans le
                    cadre de votre visite du site et de vos éventuels
                    consentements exprès selon les formulaires sur le site et
                    pour les finalités qui y sont indiquées, par VASANO
                    SOLUTIONS uniquement via ses sous-traitants techniques, aux
                    seules fins de répondre à vos demandes. Ces données ne font
                    l’objet d’aucun transfert en dehors du territoire de l’UE.
                    Elles sont traitées informatiquement mais ne donnent lieu à
                    aucune décision automatisée de la part de VASANO SOLUTIONS.
                    Vos données sont conservées pendant le seul délai nécessaire
                    à l’exécution de votre demande, augmenté des délais légaux
                    éventuels, et sauf opposition, dans le cadre de nos
                    opérations de prospection commerciale à concurrence d’un
                    délai de 3 ans au terme duquel elles sont définitivement
                    effacées sauf en cas de nouvelle demande de votre part. Si
                    par ailleurs vous avez consenti à l’implantation sur votre
                    terminal d’un dispositif de suivi de votre navigation
                    (cookie), vos données sont susceptibles de croisement avec
                    les données relatives à votre navigation sur le site, afin
                    (i) d’améliorer notre connaissance de vos besoins et (ii) de
                    vous adresser des contenus encore plus pertinents au regard
                    de vos activités professionnelles. En application de la
                    règlementation applicable à la protection des données
                    personnelles, vous disposez sur ces données de droits (i)
                    d’accès, (ii) de rectification, (iii) d’effacement, (iv) de
                    limitation et (v) de portabilité vers un prestataire tiers
                    le cas échéant. Vous pouvez en outre vous opposer à tout
                    moment au traitement de vos données. Vous pourrez faire
                    valoir ces droits en contactant notre DPO, à l’adresse «
                    dpo@vasano.fr » en justifiant de votre identité (copie de
                    votre carte d’identité, extrait k-bis et tout justificatif
                    permettant d’établir la légitimité de votre demande). Pour
                    toute information complémentaire, veuillez consulter notre
                    politique de confidentialité. En cas de litige, vous
                    disposez également du droit de saisir la CNIL (3 Place de
                    Fontenoy TSA 80715 - 75334 PARIS CEDEX 07 | TEL 01 53 73 22
                    22). Toute demande d’effacement de vos données, loisible à
                    tout moment à l’adresse précédente, est susceptible
                    d’empêcher toute commande ultérieure.
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Sécurité et propriété intellectuelle
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    Le site et chacun de ses éléments sont la propriété
                    exclusive de VASANO SOLUTIONS. Toute reproduction ou
                    représentation en tout ou partie du présent site web ou de
                    l’un des éléments qui le composent, est interdite sauf
                    autorisation préalable de VASANO SOLUTIONS. De même, les
                    marques et noms de domaine qui apparaissent sur le site sont
                    la propriété exclusive de VASANO SOLUTIONS, ou sont
                    reproduits avec l’autorisation de leurs propriétaires. Toute
                    reproduction ou utilisation de ces marques ou noms de
                    domaine de quelque manière et à quelque titre que ce soit
                    est interdite. En cas de lien hypertexte pointant vers le
                    site web de VASANO SOLUTIONS, VASANO SOLUTIONS ne peut en
                    aucun cas contrôler le site liant et décline toute
                    responsabilité à ce titre. De manière générale, VASANO
                    SOLUTIONS décline toute responsabilité relative au contenu
                    des sites tiers éventuellement liés au site web. L’Editeur
                    fait ses meilleurs efforts afin de s’assurer que les
                    informations accessibles sur son site web sont exactes.
                    Cependant, il n’assure aucune garantie, expresse ou tacite,
                    concernant le contenu de son site web. En aucun cas, VASANO
                    SOLUTIONS ne peut être tenue pour responsable d’un
                    quelconque dommage direct ou indirect de quelque nature que
                    ce soit découlant de l’utilisation de son site web.
                  </MKTypography>
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox>
        <Footer content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Privacy;
