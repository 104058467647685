import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";

// @mui icons
import CloseIcon from "@mui/icons-material/Close";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

import emailjs from "@emailjs/browser";

function Popup(data) {
  const [show, setShow] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.send(
      "service_exm11ba",
      "template_3z1442j",
      data.data,
      "6rkQKR7lpnZUIgfPE"
    );
    setShow(!show);
  };

  const toggleModal = () => {
    setShow(!show);
    window.location.href = "/";
  };

  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={10}
          justifyContent="center"
          mx="auto"
          pb={1}
        >
          <MKButton variant="gradient" color="info" onClick={sendEmail}>
            Envoyez
          </MKButton>
        </Grid>
        <Divider />
        <Grid
          container
          item
          xs={12}
          lg={15}
          justifyContent="center"
          mx="auto"
          pt={2}
        >
          <MKButton
            variant="gradient"
            color="info"
            href="https://calendly.com/m-berger-1"
            size="large"
          >
            je veux une demo !
          </MKButton>
        </Grid>
        <Modal
          open={show}
          onClose={toggleModal}
          sx={{ display: "grid", placeItems: "center" }}
        >
          <Slide direction="down" in={show} timeout={500}>
            <MKBox
              position="relative"
              width="500px"
              display="flex"
              flexDirection="column"
              borderRadius="xl"
              bgColor="white"
              shadow="xl"
            >
              <MKBox
                display="flex"
                alginItems="center"
                justifyContent="space-between"
                p={2}
              >
                <MKTypography variant="h5">Merci !</MKTypography>
                <CloseIcon
                  fontSize="medium"
                  sx={{ cursor: "pointer" }}
                  onClick={toggleModal}
                />
              </MKBox>
              <Divider sx={{ my: 0 }} />
              <MKBox p={2}>
                <MKTypography
                  variant="body2"
                  color="secondary"
                  fontWeight="regular"
                >
                  Nous allons répondre à votre message au plus vite.
                </MKTypography>
              </MKBox>
              <Divider sx={{ my: 0 }} />
              <MKBox display="flex" justifyContent="space-between" p={1.5}>
                <MKButton variant="gradient" color="dark" onClick={toggleModal}>
                  Fermer
                </MKButton>
              </MKBox>
            </MKBox>
          </Slide>
        </Modal>
      </Container>
    </MKBox>
  );
}

export default Popup;
