/*
=========================================================
* Otis Kit PRO - v2.0.0
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Coworking page component
import AboutUsOption from "pages/Home/sections/AboutUsOption";

// Images
import bg from "assets/images/offre2.jpg";

function FeaturesOne() {
  return (
    <MKBox component="section" mb={3} color="dark">
      <Container>
        <Grid container alignItems="center">
          <Grid item textAlign="center" mb={5}>
            <MKTypography variant="h2" my={1}>
              Pourquoi faire appel à VASANO SOLUTIONS ?
            </MKTypography>
            <MKTypography variant="h4" color="text" mb={2}>
              Nous vous proposons d’analyser en permanence les notes et avis
              Google de vos magasins et de les comparer aux concurrents
              implantés sur leurs zones de chalandise.
            </MKTypography>
          </Grid>
          <Grid container spacing={10}>
            <Grid item xs={12} lg={6}>
              <MKBox
                width="100%"
                height="100%"
                borderRadius="lg"
                sx={{
                  backgroundImage: `url(${bg})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Stack>
                <AboutUsOption
                  icon="bar_chart"
                  content={
                    <>
                      Evaluer votre préférence de marque.
                      <br />
                      Mesurer l’effet sur votre attractivité.
                    </>
                  }
                />
                <AboutUsOption
                  icon="compare_arrow"
                  content={
                    <>
                      Prendre le pouls du marché.
                      <br />
                      Se comparer à la concurrence.
                    </>
                  }
                />
                <AboutUsOption
                  icon="score"
                  content={
                    <>
                      Animer son réseau en comparant le score de chaque point de
                      vente. Détecter des faiblesses sous-jacentes déclarées par
                      les clients.
                    </>
                  }
                />
                <AboutUsOption
                  icon="search_icon"
                  content={
                    <>
                      Vérifier les résultats de vos actions engagées pour
                      optimiser votre relation clients.
                    </>
                  }
                />
                <AboutUsOption
                  icon="emoji_events"
                  content={
                    <>
                      Et pourquoi pas revendiquer une place sur le podium, voire
                      la première marche !
                    </>
                  }
                />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default FeaturesOne;
